import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { milliseconds, zero } from '../../../../Constants/var.constants';
import { WebviewContext } from '../../../../Context/webview.context';
import { WVAuthService } from '../../../../Services/wvauth.service';
import { useParams } from 'react-router-dom';
import messages from './messages';
import './style.css';
import { FullScreenModalBarcode } from '../../../../Components/Presentation/FullScreenModalBarcode';
import Popup from 'reactjs-popup';
import { Divider, Stack } from '@mui/material';

const MIN_INVEST = 20;
const MAX_INVEST = 500;
const TIME_OUT = 5000;

export const FormBuy: React.FC<{
    onBuy: (amount: number, name: string) => void;
    onCancel: () => void;
}> = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [btc, setBTC] = useState(zero);
    const [calculating, setCalculating] = useState(false);
    const [amount, setAmount] = useState(zero);
    const context = useContext(WebviewContext);
    const { enqueueSnackbar } = useSnackbar();
    let timeOutId;

    const { storeAddress } = useParams();
    const regex = /(<([^>]+)>)/gi;
    const result = storeAddress!.replace(regex, ' ');

    useEffect(() => {
        if (amount >= MIN_INVEST && amount <= MAX_INVEST) {
            timeOutId = setTimeout(() => getBTC(amount), milliseconds);
        }
        return () => clearTimeout(timeOutId);
    }, [amount]);

    const getBTC = async (amount: number) => {
        setCalculating(true);
        const btcResult = await WVAuthService.calculateBTC(
            {
                token: context.authToken as string,
                amount,
            },
            { enqueueSnackbar, logout: context.logout },
        );
        if (btcResult) {
            setBTC(btcResult.bitcoin);
            setCalculating(false);
            setTimeout(
                () =>
                    setButtonDisabled(
                        amount >= MIN_INVEST || amount <= MAX_INVEST,
                    ),
                TIME_OUT,
            );
        }
    };

    const terms = () => {
        return (
            <>
                <b>
                    Scroll through and read the entirety of these terms and
                    conditions.
                </b>
                <br></br>
                <br></br> - Purchases are for personal use only. Cash loads are
                non-refundable. Transaction follows through with auto-completion
                following a successful cash load.
                <br></br>
                <br></br> - Do not load cash for purchase if someone has called
                you claiming to make a payment or that you owe them money.
            </>
        );
    };

    return (
        <FullScreenModalBarcode
            message={messages.welcome}
            address={result}
            className='FormBuy'
        >
            <Popup open={isOpen}>
                <div className='modal'>
                    <div style={{ textAlign: 'left' }}> {terms()} </div>
                    <Divider orientation='horizontal' />
                    <Stack className='stack' spacing={2} direction='row'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <a
                                            className='buttonlink'
                                            onClick={() => setIsOpen(false)}
                                        >
                                            {messages.Accept}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            className='buttonlink'
                                            onClick={props.onCancel}
                                        >
                                            {messages.cancel}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Stack>
                </div>
            </Popup>

            <p>{messages.info_message}</p>
            <TextField
                id='outlined-number'
                label={messages.amount}
                // type='number'
                InputLabelProps={{
                    shrink: true,
                }}
                value={amount}
                onChange={(textAmount) => {
                    let newAmount = parseFloat(textAmount.target.value);
                    if (isNaN(newAmount)) newAmount = zero;
                    setAmount(newAmount);
                    setButtonDisabled(false);
                }}
            />
            <div>
                {!calculating && btc !== zero && (
                    <p className='Calculating'>
                        {messages.receive}
                        {btc}
                        {' BTC'}
                        <ul className='Points'>
                            <li>{messages.point_one}</li>
                            <li>{messages.point_two}</li>
                        </ul>
                    </p>
                )}
                {calculating && (
                    <p className='Calculating'>
                        {messages.calculating}
                        <CircularProgress className='Orange' />
                    </p>
                )}
            </div>
            {buttonDisabled ? (
                <Button
                    variant='contained'
                    onClick={() => {
                        props.onBuy(amount, 'xxx');
                    }}
                >
                    {messages.generate_barcode}
                </Button>
            ) : null}
            <a className='CancelLink' onClick={props.onCancel}>
                {messages.cancel}
            </a>
            <div className='footText'>
                <p>{messages.foot_text3}</p>
            </div>
        </FullScreenModalBarcode>
    );
};

import { OpenInNew } from '@mui/icons-material';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import {
    byte,
    eight,
    five,
    hexaForProfile,
    one,
    sixteen,
    three,
    two,
    // eslint-disable-next-line prettier/prettier
    zero,
} from '../Constants/var.constants';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import { ITransaction } from '../Interfaces/auth.interfaces';
import { RequestResponse } from '../Interfaces/request.interface';
import { UsersResponse } from '../Interfaces/user.interface';
import { WhitelistResponse } from '../Interfaces/whitelist.interface';
import { TransactionResponse } from '../Views/Admin/TransactionList/transactionlist.type';

/**
 * Method to create a get query params.
 * @param queryParams  & PaginationProperties
 * @returns {string}
 */
export const createQueryByFilters = (
    queryParams: (object & PaginationProperties) | object,
): string => {
    const filterQueryParams = Object.keys(queryParams).reduce(
        (init, current) => {
            const value = queryParams[current];

            if (value !== '') {
                return Object.assign(init, {
                    [current]: queryParams[current],
                });
            }

            return init;
        },
        {},
    );

    return Object.keys(filterQueryParams).reduce(
        (init, current, position, data) => {
            const max = data.length - one;

            if (position < max) {
                init += `${current}=${queryParams[current]}&`;
            } else if (position === max) {
                init += `${current}=${queryParams[current]}`;
            }

            return init;
        },
        '?',
    );
};

export const transformData = (
    columnId: string,
    data:
        | Array<UsersResponse>
        | Array<TransactionResponse>
        | Array<WhitelistResponse>
        | Array<RequestResponse>
        | []
        | Partial<ITransaction>[],
    editFn?: (id: number) => void,
    loadingAction?: number | null,
    approvalsFn?: (id: number, status: string) => void,
): Array<Array<string>> => {
    const result = data.map((item) => {
        const items = Object.keys(item).map((value) => {
            if (item[value] === true) return 'Yes';
            else if (item[value] === false) return 'No';
            if (value === 'transactionAmount') {
                return amountLink(item.id, item.transactionAmount);
            }
            if (value === 'user') {
                return userLink(item.user, item.user);
            }
            if (value === 'idImage') {
                return popupId(item.idImage);
            }
            return item[value];
        });
        if (editFn) {
            const text = item['active'] ? 'Lock' : 'Unlock';
            const loading = loadingAction === item.id;
            items.push(actionsButtons(item[columnId], editFn, text, loading));
        }
        if (approvalsFn) {
            const loading = loadingAction === item.id;
            items.push(approvalButtons(item[columnId], approvalsFn, loading));
        }
        return items;
    });
    return result;
};

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = zero; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << five) - hash);
    }

    let color = '#';

    for (i = zero; i < three; i++) {
        const value = (hash >> (i * eight)) & hexaForProfile;
        color += `00${value.toString(sixteen)}`.slice(-two);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringPhoneAvatar = (phone: string) => {
    return {
        sx: {
            bgcolor: stringToColor(phone),
        },
        children: `${phone[0]}`,
    };
};

const approvalButtons = (
    id: number,
    fn: (id: number, status: string) => void,
    loadingAction?: boolean,
): JSX.Element => {
    return (
        <>
            {loadingAction ? (
                <CircularProgress color='inherit' className='LoadingTable' />
            ) : (
                <div>
                    <Button
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        onClick={() => fn(id, 'approved')}
                    >
                        Approved
                    </Button>

                    <Button
                        style={{
                            backgroundColor: '#000',
                            color: '#fff',
                            marginLeft: '20px',
                        }}
                        onClick={() => fn(id, 'rejected')}
                    >
                        Rejected
                    </Button>
                </div>
            )}
        </>
    );
};

const actionsButtons = (
    id: number,
    fn: (id: number) => void,
    text: string,
    loadingAction?: boolean,
): JSX.Element => {
    return (
        <>
            {loadingAction ? (
                <CircularProgress color='inherit' className='LoadingTable' />
            ) : (
                <Button
                    style={{ backgroundColor: '#000', color: '#fff' }}
                    onClick={() => fn(id)}
                >
                    {text}
                </Button>
            )}
        </>
    );
};

const amountLink = (userId: number, text: string): JSX.Element => {
    return <Link to={`/admin/transactions?userId=${userId}`}>{text}</Link>;
};

const userLink = (userId: number, text: string): JSX.Element => {
    return <Link to={`/admin/users?userId=${userId}`}>{text}</Link>;
};

const popupId = (url: string): JSX.Element => {
    return (
        <Popup
            trigger={
                <ButtonInner
                    className='button'
                    variant='contained'
                    startIcon={<OpenInNew />}
                >
                    OPEN IMAGE
                </ButtonInner>
            }
            modal
        >
            <h2 className='formHeader'>ID</h2>
            <img src={`${url}`} alt='ID' />
        </Popup>
    );
};

const ButtonInner = styled(Button)<ButtonProps>(() => ({
    color: '#000',
    backgroundColor: 'hsl(60deg 75% 95%)',
    '&:hover': {
        backgroundColor: 'hsl(60deg 22% 81%)',
    },
}));

/**
 * This method return a jsx element with a bold string.
 * @param value
 * @returns {JSX.Element}
 */
export const boldString = (value: string | JSX.Element): JSX.Element => {
    return <b>{value}</b>;
};
/**
 * Remove duplicated elements in an array
 * @param arr array with duplications
 * @returns array without duplications
 */
export const removeDuplicates = (arr: any[]) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const getDynamicColor = (opacity = 0.5) => {
    const r = Math.floor(Math.random() * byte);
    const g = Math.floor(Math.random() * byte);
    const b = Math.floor(Math.random() * byte);
    return 'rgb(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FullScreenMessage } from '../../../Components/Presentation/FullScreenMessage';
import {
    AskRequest,
    InReview,
    Rejected,
    WhiteListIn,
} from '../../../Constants/request.constants';
import { useLoader } from '../../../Context/loader.context';
import { WebviewContext } from '../../../Context/webview.context';
import { EdgeProvider } from '../../../Interfaces/edge.provider.interface';
import { Edge } from '../../../Services/edgeprovider.service';
import { WVAuthService } from '../../../Services/wvauth.service';
import { FormPersonalInfo } from '../FormPersonalInfo';
import { FormPhoneRequest } from '../FormPhoneRequest';
import { FormUpdateName } from '../FormUpdateName';
import { FormValidateSmsCode } from '../FormValidateSmsCode';
import { RequestUnderReview } from '../RequestUnderReview';
import messages from './edge.messages';
import { useNavigate } from 'react-router-dom';
import { LoginBlock } from '../LoginBlock';

enum AuthFlowStatus {
    askPhone,
    smsSending,
    smsSent,
    askName,
    askPersonal,
    underReview,
    loginBlock,
}
const USERNAME_KEY = 'username';
const AUTHTOKEN_KEY = 'authToken';
const FIRST_NAME = 'firstName';
const USER_ID = 'userid';

export const EdgeAuthenticator: React.FC<{ children?: React.ReactNode }> = (
    props,
) => {
    const { startLoader, stopLoader } = useLoader();
    const [edgeProvider, setEdgeProvider] = useState({} as EdgeProvider);
    const [authToken, setAuthToken] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [userid, setUserId] = useState<number | undefined>(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const isInsideEdge = Edge.isEdge();
    const [authFlowStatus, setAuthFlowStatus] = useState(
        AuthFlowStatus.askPhone,
    );
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        startLoader();
        const fetchEdge = async () => {
            const provider = await Edge.getProvider();
            setEdgeProvider(provider);
            const result = await provider.readData([
                USERNAME_KEY,
                AUTHTOKEN_KEY,
                FIRST_NAME,
                USER_ID,
            ]);

            if (
                result[USERNAME_KEY] &&
                result[AUTHTOKEN_KEY] &&
                result[USER_ID]
            ) {
                setUsername(result[USERNAME_KEY]);
                setAuthToken(result[AUTHTOKEN_KEY]);
                setUserId(result[USER_ID]);

                console.log(result[FIRST_NAME]);

                if (!result[FIRST_NAME]) {
                    setAuthFlowStatus(AuthFlowStatus.askName);
                    stopLoader();
                } else {
                    const request = await WVAuthService.validateReview(
                        { phone: username },
                        { enqueueSnackbar, logout },
                    );
                    if (!request) {
                        if (request === InReview) {
                            setAuthFlowStatus(AuthFlowStatus.underReview);
                            stopLoader();
                        } else if (request === Rejected) {
                            logout();
                            navigate('/');
                        } else if (request === WhiteListIn) {
                            setIsLoggedIn(true);
                        }
                    }
                }
            }

            console.log('entro log');
        };

        fetchEdge().finally(() => {
            stopLoader();
        });
    }, []);

    const logout = async () => {
        const data = {};
        data[USERNAME_KEY] = '';
        data[AUTHTOKEN_KEY] = '';
        data[FIRST_NAME] = '';
        data[USER_ID] = '';
        await edgeProvider.writeData(data);
        setUsername(undefined);
        setAuthToken(undefined);
        setAuthFlowStatus(AuthFlowStatus.askPhone);
        setIsLoggedIn(false);
    };

    const sendPhone = async (phone: string) => {
        startLoader();
        const sent = await WVAuthService.sendSMS(
            { phone },
            { enqueueSnackbar, logout },
        );
        if (sent) {
            setAuthFlowStatus(AuthFlowStatus.smsSent);
            setUsername(phone);
            stopLoader();
        }
        stopLoader();
    };

    const sendCode = async (phone: string, code: string) => {
        startLoader();
        const user = await WVAuthService.validateCode(
            { phone, code },
            { enqueueSnackbar, logout },
        );
        if (user?.user) {
            const data = {};
            data[USERNAME_KEY] = user?.user.phone;
            data[AUTHTOKEN_KEY] = user?.user.token;
            data[USER_ID] = user?.user.id;
            setUsername(user?.user.phone);
            setAuthToken(user?.user.token);
            setUserId(user?.user.id);
            if (!user?.user.firstName) {
                setAuthFlowStatus(AuthFlowStatus.askName);
                stopLoader();
            } else if (user?.request === AskRequest) {
                setAuthFlowStatus(AuthFlowStatus.askPersonal);
                data[FIRST_NAME] = user?.user.firstName;
                stopLoader();
            } else if (user?.request === InReview) {
                setAuthFlowStatus(AuthFlowStatus.underReview);
                data[FIRST_NAME] = user?.user.firstName;
                stopLoader();
            } else if (user?.request === Rejected) {
                logout();
                navigate('/');
                stopLoader();
            } else {
                stopLoader();
                setIsLoggedIn(true);
                data[FIRST_NAME] = user?.user.firstName;
                stopLoader();
            }
            await edgeProvider.writeData(data);
        }
        stopLoader();
    };

    const updateNames = async (formValues: any) => {
        startLoader();
        const user = await WVAuthService.updateUserName(
            {
                token: authToken,
                input: {
                    userId: userid!,
                    firstName: formValues.name.value,
                    lastName: formValues.lastName.value,
                },
            },
            { enqueueSnackbar, logout },
        );

        if (user) {
            const data = {};
            data[USERNAME_KEY] = user.user.phone;
            data[AUTHTOKEN_KEY] = user.user.token;
            data[USER_ID] = user.user.id;
            data[FIRST_NAME] = user.user.firstName;
            //await edgeProvider.writeData(data);
            setUsername(user.user.phone);
            setAuthToken(user.user.token);
            setUserId(user.user.id);
            if (!user.user.firstName) {
                stopLoader();
                setAuthFlowStatus(AuthFlowStatus.askName);
            } else if (user?.request === AskRequest) {
                stopLoader();
                setAuthFlowStatus(AuthFlowStatus.askPersonal);
            } else if (user?.request === InReview) {
                setAuthFlowStatus(AuthFlowStatus.underReview);
                stopLoader();
            } else if (user?.request === Rejected) {
                logout();
                navigate('/');
            } else {
                stopLoader();
                setIsLoggedIn(true);
                stopLoader();
            }
            await edgeProvider.writeData(data);
        }

        stopLoader();
    };

    const createRequest = async (formValues: any) => {
        startLoader();
        formValues.append('phone', username);
        const request = await WVAuthService.createRequest(
            {
                token: authToken,
                input: {
                    file: formValues,
                },
            },
            { enqueueSnackbar, logout },
        );

        if (request) {
            setAuthFlowStatus(AuthFlowStatus.underReview);
            stopLoader();
        }

        stopLoader();
    };

    if (!isInsideEdge) {
        return <FullScreenMessage message={messages.outside_edge} />;
    }

    const updateReview = async () => {
        const login = await WVAuthService.getsetting({
            enqueueSnackbar,
            logout,
        });

        if (login.body.appLogin) {
            if (authFlowStatus === AuthFlowStatus.underReview) {
                const request = await WVAuthService.validateReview(
                    { phone: username },
                    { enqueueSnackbar, logout },
                );

                if (!request) {
                    if (request === InReview) {
                        setAuthFlowStatus(AuthFlowStatus.underReview);
                    } else if (request === Rejected) {
                        logout();
                        navigate('/');
                    } else if (request === WhiteListIn) {
                        setIsLoggedIn(true);
                    }
                }
            }

            if (authFlowStatus === AuthFlowStatus.loginBlock) {
                setAuthFlowStatus(AuthFlowStatus.askPhone);
            }
        } else {
            setAuthFlowStatus(AuthFlowStatus.loginBlock);
            stopLoader();
        }
    };

    const getAuthHandler = () => {
        updateReview();
        if (authFlowStatus === AuthFlowStatus.askPhone) {
            return (
                <FormPhoneRequest onSendPhone={sendPhone}></FormPhoneRequest>
            );
        } else if (authFlowStatus === AuthFlowStatus.smsSent) {
            return (
                // Implement the verify code modal
                <FormValidateSmsCode
                    phone={username as string}
                    onValidateCode={sendCode}
                    onResendCode={() => {
                        sendPhone(username as string);
                        enqueueSnackbar(messages.sms_sent, {
                            autoHideDuration: 3000,
                        });
                    }}
                    onNavigateBack={() => {
                        setAuthFlowStatus(AuthFlowStatus.askPhone);
                    }}
                ></FormValidateSmsCode>
            );
        } else if (authFlowStatus === AuthFlowStatus.askName) {
            return (
                <FormUpdateName onUpdateNames={updateNames}></FormUpdateName>
            );
        } else if (authFlowStatus === AuthFlowStatus.askPersonal) {
            return (
                <FormPersonalInfo
                    onCreateRequest={createRequest}
                ></FormPersonalInfo>
            );
        } else if (authFlowStatus === AuthFlowStatus.underReview) {
            return (
                <RequestUnderReview
                    onSendPhone={undefined}
                    onCancel={() => {
                        navigate('/');
                    }}
                ></RequestUnderReview>
            );
        } else if (authFlowStatus === AuthFlowStatus.loginBlock) {
            return (
                <LoginBlock
                    onCancel={() => {
                        navigate('/');
                    }}
                ></LoginBlock>
            );
        }
        return <FormPhoneRequest onSendPhone={sendPhone}></FormPhoneRequest>;
    };

    return (
        <>
            {!isLoggedIn ? (
                location.pathname != '/' && location.pathname != '/tellers' ? (
                    getAuthHandler()
                ) : (
                    <WebviewContext.Provider
                        value={{
                            edgeProvider,
                            username,
                            authToken,
                            logout,
                            isLoggedIn,
                        }}
                    >
                        <div>{props.children}</div>
                    </WebviewContext.Provider>
                )
            ) : (
                <WebviewContext.Provider
                    value={{
                        edgeProvider,
                        username,
                        authToken,
                        logout,
                        isLoggedIn,
                    }}
                >
                    <div>{props.children}</div>
                </WebviewContext.Provider>
            )}
        </>
    );
};

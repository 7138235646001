const messages = {
    welcome: 'Load Cash To Wallet At Retailer:',
    info_message:
        'Please enter the amount of cash you would like to load between $20 and $500',
    generate_barcode: 'Continue',
    amount: 'Amount',
    name: 'Name',
    receive: 'Estimated Bitcoin Receipt ~ ',
    calculating: 'Calculating bitcoins...',
    cancel: 'Cancel',
    point_one:
        'Bitcoin receipt is calculated at the time of completed purchase.',
    point_two: 'Bitcoin is sent to your wallet.',
    error_name: 'Name field is required',
    foot_text3: 'Subject To Terms & Conditions',
    Accept: 'Accept',
};

export default messages;

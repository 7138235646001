import React from 'react';
import { two } from '../../../Constants/var.constants';
import './style.css';

export const FullScreenContainer: React.FC<{
    children?: React.ReactNode;
    zIndex?: number;
}> = (props) => {
    return (
        <div className='FullScreen' style={{ zIndex: props.zIndex || two }}>
            {props.children}
        </div>
    );
};

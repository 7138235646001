/* eslint-disable no-magic-numbers */
export const fifty = 50;
export const hundred = 100;
export const one = 1;
export const ten = 10;
export const thirty = 30;
export const three = 3;
export const eightTeen = 18;
export const eight = 8;
export const sixteen = 16;
export const twentyOne = 21;
export const two = 2;
export const zero = 0;
export const five = 5;
export const four = 4;
export const milliseconds = 1000;
export const fifteen = 15;
export const fiftyNine = 59;
export const seven = 7;
export const sevenTeen = 17;
export const thirteen = 13;
export const nine = 9;
export const six = 6;
export const drawerWidth = 200;
export const thirtytwo = 32;
export const hexaForProfile = 0xff;
export const byte = 255;

/**
 * Default value for input
 */
export const defaultValue = '';

import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LoaderProvider } from './Context/loader.context';
import './index.css';
import { Dashboard } from './Views/Admin/Dashboard';
import Shell from './Views/Admin/Shell';
import { TransactionList } from './Views/Admin/TransactionList';
import { UsersList } from './Views/Admin/UsersList';
import { NoPage } from './Views/NoPage';
import { EdgeAuthenticator } from './Views/Registration/EdgeAuthenticator';
import { Instructions } from './Views/WebView/Instructions';
import { Map } from './Views/WebView/Map';
import { BuyBTC } from './Views/WebView/Map/BuyBTC';
import { UserTransactionList } from './Views/WebView/UserTransactionList';
import WVShell from './Views/WebView/WVShell';
import React from 'react';
import { WhiteList } from './Views/Admin/PhoneWithelist';
import { Request } from './Views/Admin/Request';
import { Setting } from './Views/Admin/Setting';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

const {
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    REACT_APP_AWS_COGNITO_POOL_ID,
} = process.env;

const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
        userPoolId: REACT_APP_AWS_COGNITO_POOL_ID!,
        userPoolClientId: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID!,
    },
};

Amplify.configure({
    Auth: authConfig,
});
/**
 * Main Application component to render all the application
 */
const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/admin/*'>
                    <Route
                        index
                        element={
                            <AdminAuthWrapper>
                                <Dashboard />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route
                        path='users'
                        element={
                            <AdminAuthWrapper>
                                <UsersList />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route
                        path='transactions'
                        element={
                            <AdminAuthWrapper>
                                <TransactionList />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route
                        path='whitelist'
                        element={
                            <AdminAuthWrapper>
                                <WhiteList />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route
                        path='request'
                        element={
                            <AdminAuthWrapper>
                                <Request />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route
                        path='setting'
                        element={
                            <AdminAuthWrapper>
                                <Setting />
                            </AdminAuthWrapper>
                        }
                    />
                    <Route path='*' element={<NoPage />} />
                </Route>
                <Route path='/*'>
                    <Route
                        index
                        element={
                            <AuthWrapper>
                                <Map />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path='map'
                        element={
                            <AuthWrapper>
                                <Map />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path='tellers'
                        element={
                            <AuthWrapper>
                                <Map />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path='transactions'
                        element={
                            <AuthWrapper>
                                <UserTransactionList />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path='instructions'
                        element={
                            <AuthWrapper>
                                <Instructions />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path='buy-btc/:storeId/:storeName/:storeAddress'
                        element={
                            <AuthWrapper>
                                <BuyBTC />
                            </AuthWrapper>
                        }
                    />
                    <Route path='*' element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;

const AuthWrapper: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <SnackbarProvider maxSnack={1}>
            <LoaderProvider>
                <EdgeAuthenticator>
                    <WVShell>{props.children}</WVShell>
                </EdgeAuthenticator>
            </LoaderProvider>
        </SnackbarProvider>
    );
};

const AdminAuthWrapper: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <Authenticator hideSignUp={true}>
            {({ signOut, user }) => (
                <Shell userData={{ signOut, user }}>{props.children}</Shell>
            )}
        </Authenticator>
    );
};

const messages = {
    info: 'SUCCESS',
    info_wait:
        'Please Allow Up To 45 Minutes For Bitcoin To Arrive To Your Wallet',
    current_exchange: 'Current Exchange Rate For BTC:',
    bitcoin_sent: 'Bitcoin Amount Sent: ',
    bitcoin_re: 'Receive Bitcoin:',
    date: 'Date: ',
    cash_amount: 'Cash Amount: ',
    purchase_non_refundable: 'Purchases Are Non-Refundable',
    values_may_vary:
        'The amount to receive may vary depending on the Exchange rate at the moment of processing the transaction',
    cancel: 'Cancel',
    continue: 'View Receipts',
    wallet_label: 'Your Coinhub Wallet Address:',
};

export default messages;

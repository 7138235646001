/**
 * Http status and response constant.
 */
export const HttpStatusResponse = {
    ok: {
        status: 200,
    },
    created: {
        status: 201,
        description: 'Created successfully.',
    },
    badRequest: {
        status: 400,
        description: 'Bad Request.',
    },
    unauthorized: {
        status: 401,
        description: 'Not authorized.',
    },
    forbidden: {
        status: 403,
        description: 'Forbidden.',
    },
    notFound: {
        status: 404,
        description: 'Not Found.',
    },
    notProcessable: {
        status: 422,
        description: 'Non-Processable Entity.',
    },
    serverError: {
        status: 500,
        description: 'An internal server error occurred.',
    },
};

import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    ButtonProps,
    CircularProgress,
    styled,
    // eslint-disable-next-line prettier/prettier
    TextField,
} from '@mui/material';

import { TableInner } from '../../../Components/Tables/TableInner';
import { ten, zero } from '../../../Constants/var.constants';
import {
    UserFormValues,
    // eslint-disable-next-line prettier/prettier
} from '../../../Interfaces/user.interface';
import { transformData } from '../../../Utils/general.utils';
import './whitelist.css';
import { WhitelistResponse } from '../../../Interfaces/whitelist.interface';
import { WhitelistService } from '../../../Services/whitelist.service';
import Popup from 'reactjs-popup';
import { AddCircle, UploadFile, UploadFileSharp } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

export const WhiteList: React.FC = () => {
    const [users, setWhitelist] = useState<Array<WhitelistResponse> | []>([]);
    const [dataTable, setDataTable] = useState(Array<Array<string>>);
    const [formData, setFormData] = useState<UserFormValues>(
        {} as UserFormValues,
    );
    const [page, setPage] = React.useState(zero);
    const [rowsPerPage, setRowsPerPage] = React.useState(ten);
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState<number | null>(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log(event);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, ten));
        setPage(zero);
    };

    /**
     * Handle onChange method
     * @param key input id
     * @param value input value
     */
    const onChangeDataForm = (key, value) => {
        let newValue = value;
        if (key === 'phone') {
            newValue = encodeURIComponent(newValue);
        }
        setFormData({ ...formData, [key]: newValue });
    };

    /**
     * Method to call the whitelist.
     * @param offset number
     * @param limit number
     * @returns {Promise<Array<WhitelistResponse>>}
     */
    const getWhitelist = (
        offset: number,
        limit: number,
        afterEdit?: boolean,
    ): Promise<Array<WhitelistResponse | []>> => {
        !afterEdit && setLoading(true);
        const service = new WhitelistService();
        return new Promise((resolve) => {
            service
                .getAllWhiteListPhones({
                    ...formData,
                    offset,
                    limit,
                })
                .then((response) => {
                    setWhitelist(response);
                    const data = transformData(
                        'id',
                        response,
                        editWhitelist,
                        loadingAction,
                    );
                    setDataTable(data);
                    setLoading(false);
                    setLoadingAction(null);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    };

    /**
     * Method to edit one phone whitelist.
     * @param whitelistId whitelist identifier.
     */
    const editWhitelist = (whitelistId: number) => {
        setLoadingAction(whitelistId);
        const offset = 0;
        const limit = 10;
        const service = new WhitelistService();
        return new Promise(() => {
            service
                .editWhitelist(whitelistId)
                .then(() => {
                    toast.success('Edited phone!');
                    getWhitelist(offset, limit, true);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error);
                    setLoadingAction(null);
                });
        });
    };

    /**
     * Method to add new phone whitelist.
     * @param whitelistId whitelist identifier.
     */
    const addWhitelist = (phone: string, active: boolean) => {
        const offset = 0;
        const limit = 10;
        setLoadingAction(limit);
        const service = new WhitelistService();
        return new Promise(() => {
            service
                .addNewWhitelist(phone, active)
                .then(() => {
                    toast.success('New phone added!');
                    setLoadingAction(null);
                    getWhitelist(offset, limit, true);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error);
                    setLoadingAction(null);
                });
        });
    };

    useEffect(() => {
        getWhitelist(page, rowsPerPage);
    }, []);

    useEffect(() => {
        const data = transformData('id', users, editWhitelist, loadingAction);
        setDataTable(data);
    }, [loadingAction]);

    useEffect(() => {
        const count = page * rowsPerPage;
        getWhitelist(count, count + rowsPerPage);
    }, [rowsPerPage, page]);

    const onSubmit = (data) => {
        addWhitelist(data.phone, data.active);
        reset();
    };

    const handleSubmitFile = () => {
        const formData = new FormData();
        formData.append('selectedFile', selectedFile);
        const offset = 0;
        const limit = 10;
        setLoadingAction(limit);
        const service = new WhitelistService();
        return new Promise(() => {
            service
                .bulkUpload(formData)
                .then(() => {
                    setLoadingAction(null);
                    getWhitelist(offset, limit, true);
                    setIsOpen(false);
                    setSelectedFile(null);
                    toast.success('Added phones!');
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error);
                    setLoadingAction(null);
                });
        });
    };

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const headers = ['Whitelist Id', 'Phone', 'Active', 'Action'];

    const ButtonInner = styled(Button)<ButtonProps>(() => ({
        color: '#000',
        backgroundColor: 'hsl(60deg 75% 95%)',
        '&:hover': {
            backgroundColor: 'hsl(60deg 22% 81%)',
        },
    }));

    return (
        <div className='ContainerUsers'>
            <div>
                <Toaster />
            </div>
            <h1>Whitelist</h1>
            <div className='FilterUserContainer'>
                <TextField
                    id='outlined-phone'
                    label='Phone'
                    onChange={(event) =>
                        onChangeDataForm('phone', event.target.value)
                    }
                />
                <ButtonInner
                    variant='contained'
                    onClick={() => getWhitelist(zero, ten)}
                    startIcon={<SearchIcon />}
                >
                    Search
                </ButtonInner>

                <Popup
                    trigger={
                        <ButtonInner
                            className='button'
                            variant='contained'
                            startIcon={<UploadFileSharp />}
                        >
                            Bulk upload
                        </ButtonInner>
                    }
                    open={isOpen}
                    onOpen={() => setIsOpen(!isOpen)}
                    modal
                >
                    <h2 className='formHeader'>Bulk data</h2>
                    <input type='file' onChange={handleFileSelect} />
                    <ButtonInner
                        variant='contained'
                        onClick={handleSubmitFile}
                        startIcon={<UploadFile />}
                    >
                        Send file
                    </ButtonInner>
                </Popup>

                <Popup
                    trigger={
                        <ButtonInner
                            className='button'
                            variant='contained'
                            startIcon={<AddCircle />}
                        >
                            Add new
                        </ButtonInner>
                    }
                    modal
                >
                    <h2 className='formHeader'>New Phone</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>Phone</label>
                        <div>
                            <input
                                type='text'
                                {...register('phone', {
                                    required: true,
                                    maxLength: 12,
                                    pattern: /^(\+\d{11})/i,
                                })}
                            />
                            {errors.phone && (
                                <p>Phone is required, Example : +12345678912</p>
                            )}
                        </div>

                        <label>Active</label>
                        <input
                            type='checkbox'
                            {...register('active', {
                                required: false,
                            })}
                        />

                        <button type='submit'>Save</button>
                    </form>
                </Popup>
            </div>
            {loading ? (
                <CircularProgress
                    color='inherit'
                    className='LoadingTable'
                    style={{ marginTop: '50px' }}
                />
            ) : (
                <TableInner
                    headers={headers}
                    data={dataTable}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

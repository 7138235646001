import { FullScreenContainer } from '../../Containers/FullScreenContainer';
import './style.css';

export const FullScreenModalConfirmation: React.FC<{
    children?: React.ReactNode;
    message?: string;
    address?: string;
    className?: string;
}> = (props) => (
    <>
        <FullScreenContainer>
            <div className='FullScreenModalOutBox'>
                <h3>{props.message}</h3>
                {props.children}
            </div>
        </FullScreenContainer>
    </>
);

const messages = {
    welcome: 'Information',
    otp_info_message:
        'Our retailer cash load locations are currently unavailable while upgrading our service.',
    otp_info_row2: `Please visit a Coinhub Bitcoin ATM location by viewing our ATM map. \n
    Ours ATMs also provide higher $25,000 daily limits.`,
    return: 'Done',
};

export default messages;

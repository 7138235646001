import { FullScreenContainer } from '../../Containers/FullScreenContainer';
import './style.css';

export const FullScreenModal: React.FC<{
    children?: React.ReactNode;
    message?: string;
    className?: string;
}> = (props) => (
    <>
        <FullScreenContainer>
            <div className='FullScreenModalOutBox'>
                <h3>{props.message}</h3>
                <div className={'FullScreenModalInBox' + ' ' + props.className}>
                    {props.children}
                </div>
            </div>
        </FullScreenContainer>
    </>
);

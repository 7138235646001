import React, { useContext, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

import { useSnackbar } from 'notistack';
import { FilterForm } from '../../../Components/Tables/FilterForm';
import { CatalogsForm } from '../../../Components/Tables/FilterForm/filterform.type';
import { TableInner } from '../../../Components/Tables/TableInner';
import { ten, zero } from '../../../Constants/var.constants';
import { WebviewContext } from '../../../Context/webview.context';
import { PaginationProperties } from '../../../Interfaces/app-general.interface';
import { WVAuthService } from '../../../Services/wvauth.service';
import { transformData } from '../../../Utils/general.utils';
import { stagesCatalog } from '../../Admin/TransactionList/transaction.const';
import { TransactionFormValues } from '../../Admin/TransactionList/transactionlist.type';
import messages from './messages';
import './style.css';
import { formProviderTransactions, headers } from './table.const';

const ButtonInner = styled(Button)<ButtonProps>(() => ({
    color: '#000',
    backgroundColor: 'hsl(60deg 75% 95%)',
    '&:hover': {
        backgroundColor: 'hsl(60deg 22% 81%)',
    },
}));

export const UserTransactionList: React.FC = () => {
    const context = useContext(WebviewContext);
    const [dataTable, setDataTable] = useState<string[][]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);

    const [page, setPage] = React.useState(zero);
    const [rowsPerPage, setRowsPerPage] = React.useState(ten);
    const [formData, setFormData] = useState<TransactionFormValues>(
        {} as TransactionFormValues,
    );

    /**
     * Catalogs
     */
    const [formCatalogs] = useState<CatalogsForm>({
        stages: stagesCatalog,
    });

    useEffect(() => {
        getTransactions(page, rowsPerPage);
    }, []);

    useEffect(() => {
        const count = page * rowsPerPage;
        getTransactions(count, count + rowsPerPage);
    }, [rowsPerPage, page]);

    /**
     * Handle onChange method
     * @param key input id
     * @param value input value
     */
    const onChangeDataForm = (key, value) => {
        const newValue = value;
        setFormData({ ...formData, [key]: newValue });
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log(event);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, ten));
        setPage(zero);
    };

    /**
     * Method to call the transactions.
     * @param offset number
     * @param limit number
     * @returns {Promise<Array<UsersResponse>>}
     */
    const getTransactions = (offset: number, limit: number): Promise<void> => {
        setLoading(true);
        const params: TransactionFormValues & PaginationProperties = {
            ...formData,
            offset,
            limit,
        };
        const token = context.authToken as string;
        return new Promise((resolve) => {
            WVAuthService.getUserTransactions(params, token, {
                enqueueSnackbar,
                logout: context.logout,
            })
                .then((response) => {
                    const data = transformData('id', response.list);
                    setDataTable(data);
                    setLoading(false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    };

    return (
        <div className='UserTransactionListContainer'>
            <h2 className='UserTransactionListHeader'>{messages.header}</h2>
            <FilterForm
                formId={'transaction-list-admin'}
                catalogs={formCatalogs}
                onChangeDataForm={onChangeDataForm}
                formProvider={formProviderTransactions}
                dataForm={formData}
            />
            <div className='SearchContainer'>
                <ButtonInner
                    variant='contained'
                    onClick={() => getTransactions(zero, ten)}
                    startIcon={<SearchIcon />}
                >
                    Search
                </ButtonInner>
            </div>
            {loading ? (
                <CircularProgress
                    color='inherit'
                    className='LoadingTable'
                    style={{ marginTop: '50px' }}
                />
            ) : (
                <TableInner
                    headers={headers}
                    data={dataTable}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

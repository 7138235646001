import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import messages from './footer.messages';
import './footer.css';

export const Footer: React.FC = () => {
    return (
        <div className='ContainerFooter'>
            <div className='LinksContainerFooter'>
                <ReactRouterLink className='LinkFooter' to={'/privacy'}>
                    {messages.privacy}
                </ReactRouterLink>
                <ReactRouterLink className='LinkFooter' to={'/privacy'}>
                    {messages.service}
                </ReactRouterLink>
            </div>
            <div className='Text'>{messages.copyright}</div>
        </div>
    );
};

import {
    faCircleCheck,
    faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreenModalConfirmation } from '../../../../Components/Presentation/FullScreenConfirmations';
import { ITransaction } from '../../../../Interfaces/auth.interfaces';
import messages from './messages';
import './style.css';

const SATOSHI_CONVERSION_RATE = 100000000;

export const ShowSucces: React.FC<{
    transData: ITransaction;
    onContinue: () => void;
}> = (props) => (
    <>
        <FullScreenModalConfirmation
            message={messages.info}
            className='ShowTran'
        >
            <FontAwesomeIcon
                icon={faCircleCheck}
                className='iconAlertSuccess'
                size='7x'
            />
            <div className='BitcointDetails'>
                <div className='info'>{messages.info_wait}</div>
                <div className='bitcoinInfo'>
                    <div>{messages.bitcoin_sent}</div>
                    <div>
                        {props.transData.txTotal / SATOSHI_CONVERSION_RATE}
                        {' BTC'}
                    </div>
                </div>
                <div className='walletInfo'>
                    <div>{messages.wallet_label}</div>
                    <div>{props.transData.address}</div>
                </div>
            </div>
            <a className='continue' onClick={props.onContinue}>
                <div>
                    {messages.continue}
                    <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className='iconConfirm'
                        size='1x'
                    />
                </div>
            </a>
        </FullScreenModalConfirmation>
    </>
);

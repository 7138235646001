import axios from 'axios';
import { HttpStatusResponse } from '../Constants/http-status.const';
import {
    IStatisticsStores,
    IStatisticsTransactionDataPoint,
    IStatisticsUsers,
    // eslint-disable-next-line prettier/prettier
    IStatisticsWalletBalance,
} from '../Interfaces/statistics.interface';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data request of the Statistic dashboard
 */
export class StatisticsService extends AppService {
    public async getTransactionsReceivedVSCompleted(): Promise<
        IStatisticsTransactionDataPoint[]
    > {
        const path = `${this.apiUrl}/${this.controllerPathStatisticstransactions}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async getStores(): Promise<IStatisticsStores[]> {
        const path = `${this.apiUrl}/${this.controllerPathStatisticsStores}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async getTopUsers(): Promise<IStatisticsUsers[]> {
        const path = `${this.apiUrl}/${this.controllerPathStatisticsUsers}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async getWalletBalance(): Promise<IStatisticsWalletBalance> {
        const path = `${this.apiUrl}/${this.controllerPathStatisticsWallet}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body;
            }
        } catch (error) {
            throw error;
        }
        return {
            btc: 0,
            usd: 0,
        };
    }
}

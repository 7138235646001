import './style.css';

export const Instructions = () => (
    <div className='InstructionContainer'>
        <h2 className='InstructionH2First'>How To Buy Bitcoin</h2>
        <h2 className='InstructionH2Second'>At Coinhub Bitcoin Tellers</h2>

        <img
            src='images/iPhone%2B8%2B-%2BSilver1.png'
            alt='Find Your Nearest Retailer'
        />
        <h3>1. Find Your Nearest Retailer And Click LOAD CASH</h3>
        <p>
            Choose from over 20,000 retailers to load cash at and make a
            purchase. Click the LOAD CASH Button upon making a location
            selection and head to the store.
        </p>
        <img
            src='images/iPhone%2B8%2B-%2BSilveriumage2%2B%281%29.png'
            alt='Barcode Ready To Be Scanned'
        />
        <h3>2. Have The Barcode Ready To Be Scanned</h3>
        <p>
            Have the purchase Barcode ready while waiting in line at the
            retailer.
        </p>

        <img
            src='images/iPhone%2B8%2B-%2BSilver22.png'
            alt='Scan The Provided Barcode'
        />
        <h3>3. Have The Cashier Scan The Provided Barcode</h3>
        <p>
            Show the cashier the purchase Barcode and pay the retailer service
            fee of $4.95* at the store.
        </p>

        <img
            src='images/Screen%2BShot%2B2022-07-18%2Bat%2B7.50.25%2BPM.png'
            alt='Hand Cash To Cashier'
        />
        <h3>
            4. Hand Cash To Cashier And Keep Your Receipt ($500 Limit/$1500 per
            day)
        </h3>
        <p>
            Purchases at the retailer allow for a maximum of $500 per
            transaction up to three times per day for a total of $1500. Monthly
            limits are $5000. Confirm the amount of cash handed to the retailer;
            you may be requested to show identification depending on the store.
            Make sure to keep a physical receipt of your purchase.
        </p>
        <img
            src='images/iPhone%2B8%2B-%2BSilver22purchased.png'
            alt='Purchase Complete'
        />
        <h3>
            5. Purchase Complete And Bitcoin Will Arrive In Your Bitcoin Wallet!
        </h3>
        <p>
            Your purchase has been completed and Bitcoin has been instantly sent
            to your wallet. Please allow up to 45 minutes for Bitcoin to show up
            in your wallet due to blockchain confirmations.
        </p>
    </div>
);

import React from 'react';

import {
    CircularProgress,
    InputLabel,
    MenuItem,
    Select,
    // eslint-disable-next-line prettier/prettier
    TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { defaultValue } from '../../../Constants/var.constants';
import { boldString } from '../../../Utils/general.utils';
import './filterform.css';
import { CatalogsForm, FormLabelsData } from './filterform.type';

export const FilterForm: React.FC<{
    formId: string;
    formProvider: Array<FormLabelsData>;
    catalogs?: CatalogsForm;
    onChangeDataForm: (key: string, value: string, hasError?: boolean) => void;
    dataForm?: object;
}> = ({ formId, catalogs, onChangeDataForm, formProvider, dataForm }) => {
    /**
     * Form Select input
     * @param field input properties
     * @returns
     */
    const selectElement = (field: FormLabelsData): JSX.Element => {
        return (
            <FormControl style={{ minWidth: field.sizeClass, width: '100%' }}>
                <InputLabel id={field.labelId}>
                    {field.boldLabel ? boldString(field.label) : field.label}
                </InputLabel>
                <Select
                    labelId={field.labelId}
                    id={field.elementId}
                    value={
                        dataForm && dataForm[field.elementId]
                            ? dataForm[field.elementId]
                            : field.defaultValue ?? defaultValue
                    }
                    onChange={(event) =>
                        onChangeDataForm(field.elementId, event.target.value)
                    }
                >
                    {field.catalog &&
                        catalogs &&
                        catalogs[field.catalog] &&
                        catalogs[field.catalog].catalog.map((elem, index) => (
                            <MenuItem
                                key={index}
                                value={elem[field.catalogId!]}
                            >
                                {elem[field.catalogName!]}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        );
    };

    /**
     * Form input for type text
     * @param field input properties
     * @returns
     */
    const textElement = (field: FormLabelsData) => (
        <TextField
            id={field.elementId}
            style={{ minWidth: field.sizeClass }}
            label={field.boldLabel ? boldString(field.label) : field.label}
            onChange={(event) =>
                onChangeDataForm(field.elementId, event.target.value)
            }
        />
    );

    /**
     * Spinner element
     * @returns Spinner
     */
    const spinner = (): JSX.Element => (
        <CircularProgress color='inherit' className='LoadingTable' />
    );

    /**
     * It creates the form based on the properties in the formProvider
     * @returns Array<JSX.Element> form
     */
    const form = (): Array<JSX.Element> => {
        return formProvider.map((field: FormLabelsData) => {
            const { type } = field;
            switch (type) {
                case 'text':
                case 'number':
                    return (
                        <div key={field.elementId} className={field.styleClass}>
                            {textElement(field)}
                        </div>
                    );
                case 'select':
                    const loadingSelect =
                        catalogs &&
                        catalogs[field.catalog!] &&
                        catalogs[field.catalog!].isLoading;
                    return (
                        <div key={field.elementId} className={field.styleClass}>
                            {loadingSelect ? spinner() : selectElement(field)}
                        </div>
                    );
                default:
                    return <div id={field.elementId} key={field.elementId} />;
            }
        });
    };

    return (
        <div id={formId} className='FilterContainer'>
            {form()}
        </div>
    );
};

import React from 'react';
import { FullScreenContainer } from '../../Containers/FullScreenContainer';

export const FullScreenMessage: React.FC<{ message: string }> = (props) => {
    return (
        <FullScreenContainer>
            <p
                className='MessageYellow'
                style={{ padding: '0 25px', textAlign: 'center' }}
            >
                {props.message}
            </p>
        </FullScreenContainer>
    );
};

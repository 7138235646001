import axios from 'axios';

import { HttpStatusResponse } from '../Constants/http-status.const';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import {
    UserFormValues,
    // eslint-disable-next-line prettier/prettier
    UsersResponse,
} from '../Interfaces/user.interface';
import { createQueryByFilters } from '../Utils/general.utils';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data request of the Users
 */
export class UserService extends AppService {
    public async getAllUsers(
        queryParams: UserFormValues & PaginationProperties,
    ): Promise<Array<UsersResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathUsers}${query}`;
        console.log(path);
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body.list;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async editUser(userId: number): Promise<string> {
        const path = `${this.apiUrl}/${this.controllerPathUsers}/${userId}`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

import React from 'react';
import { BitcoinPriceGraph } from '../../../Components/Graphs/BitcoinPriceGraph';
import { StoresGraph } from '../../../Components/Graphs/StoresGraph';
import { TopUsersGraph } from '../../../Components/Graphs/TopUsers';
import { TransactionsGraph } from '../../../Components/Graphs/TransactionsGraph';
import { WalletBalance } from '../../../Components/Graphs/WalletBalance';

export const Dashboard: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <h1>Dashboard</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '30px',
                    }}
                >
                    <div style={{ width: '50%' }}>
                        <WalletBalance />
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                        <StoresGraph width='50%' />
                        <TopUsersGraph width='50%' />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <BitcoinPriceGraph width='50%' />
                    <TransactionsGraph width='50%' />
                </div>
            </div>
        </div>
    );
};

import React, {
    // eslint-disable-next-line prettier/prettier
    useState,
} from 'react';
import { FullScreenLoader } from '../Components/Presentation/FullScreenLoader';

const LoaderContext = React.createContext({
    startLoader: () => {
        /* empty intentional */
    },
    stopLoader: () => {
        /* empty intentional */
    },
});

export function LoaderProvider({ children }) {
    const [loading, setLoading] = useState(false);
    return (
        <LoaderContext.Provider
            value={{
                startLoader: () => setLoading(true),
                stopLoader: () => setLoading(false),
            }}
        >
            {loading && <FullScreenLoader />}
            {children}
        </LoaderContext.Provider>
    );
}

export const useLoader = () => React.useContext(LoaderContext);

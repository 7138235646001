import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { FullScreenModal } from '../../../Components/Presentation/FullScreenModal';
import messages from './messages';
import './style.css';

export const FormUpdateName: React.FC<{ onUpdateNames: any }> = (props) => {
    const [formValues, setFormValues] = useState({
        name: {
            value: '',
            error: false,
            errorMessage: 'You must enter a first name',
        },
        lastName: {
            value: '',
            error: false,
            errorMessage: 'You must enter a last name',
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
            },
        });
    };

    return (
        <FullScreenModal message={messages.profile} className='FormName'>
            <Box
                component='form'
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '28ch' },
                }}
                autoComplete='off'
            >
                <div className='nameContainer'>
                    <TextField
                        placeholder='Enter your first name'
                        label='First Name'
                        name='name'
                        variant='outlined'
                        fullWidth
                        required
                        value={formValues.name.value}
                        onChange={handleChange}
                        error={formValues.name.error}
                        helperText={
                            formValues.name.error &&
                            formValues.name.errorMessage
                        }
                    />
                    <TextField
                        placeholder='Enter your last name'
                        label='Last Name'
                        name='lastName'
                        variant='outlined'
                        fullWidth
                        required
                        value={formValues.lastName.value}
                        onChange={handleChange}
                        error={formValues.lastName.error}
                        helperText={
                            formValues.lastName.error &&
                            formValues.lastName.errorMessage
                        }
                    />
                </div>
            </Box>
            <Button
                variant='contained'
                onClick={() => {
                    const formFields = Object.keys(formValues);
                    let newFormValues = { ...formValues };

                    for (let index = 0; index < formFields.length; index++) {
                        const currentField = formFields[index];
                        const currentValue = formValues[currentField].value;

                        if (currentValue === '') {
                            newFormValues = {
                                ...newFormValues,
                                [currentField]: {
                                    ...newFormValues[currentField],
                                    error: true,
                                },
                            };
                            setFormValues(newFormValues);
                            return;
                        }
                    }
                    setFormValues(newFormValues);
                    props.onUpdateNames(newFormValues);
                }}
            >
                {messages.send}
            </Button>
        </FullScreenModal>
    );
};

const messages = {
    personal: 'Identity Verification',
    otp_info_message: 'First Name',
    send: 'Next',
    name: 'First Name',
    last_name: 'Last Name',
    error_name: 'First name required',
    error_last_name: 'Last name required',
    error_size_ssn: 'Your number must be a maximum of 15 characters',
    error_file: 'Please select a file',
    error_SSN: 'You must enter a SSN',
};

export default messages;

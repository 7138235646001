import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

import { FilterForm } from '../../../Components/Tables/FilterForm';
import { CatalogsForm } from '../../../Components/Tables/FilterForm/filterform.type';
import { TableInner } from '../../../Components/Tables/TableInner';
import { ten, zero } from '../../../Constants/var.constants';
import { PaginationProperties } from '../../../Interfaces/app-general.interface';
import { TransactionsService } from '../../../Services/transactions.service';
import { transformData } from '../../../Utils/general.utils';
import {
    formProviderTransactions,
    headers,
    // eslint-disable-next-line prettier/prettier
    stagesCatalog,
} from './transaction.const';
import './transactionlist.css';
import {
    TransactionFormValues,
    // eslint-disable-next-line prettier/prettier
    TransactionResponse,
} from './transactionlist.type';

const ButtonInner = styled(Button)<ButtonProps>(() => ({
    color: '#000',
    backgroundColor: 'hsl(60deg 75% 95%)',
    '&:hover': {
        backgroundColor: 'hsl(60deg 22% 81%)',
    },
}));

export const TransactionList: React.FC = () => {
    const [dataTable, setDataTable] = useState(Array<Array<string>>);
    const [formData, setFormData] = useState<TransactionFormValues>(
        {} as TransactionFormValues,
    );
    const [page, setPage] = React.useState(zero);
    const [rowsPerPage, setRowsPerPage] = React.useState(ten);
    const [loading, setLoading] = useState(true);

    const [searchParams] = useSearchParams();

    /**
     * Catalogs
     */
    const [formCatalogs] = useState<CatalogsForm>({
        stages: stagesCatalog,
    });

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log(event);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, ten));
        setPage(zero);
    };

    /**
     * Method to call the transactions.
     * @param offset number
     * @param limit number
     * @returns {Promise<Array<UsersResponse>>}
     */
    const getTransactions = (
        offset: number,
        limit: number,
    ): Promise<Array<TransactionResponse | []>> => {
        setLoading(true);
        const service = new TransactionsService();
        const user = searchParams.get('userId');
        let params: TransactionFormValues & PaginationProperties = {
            ...formData,
            offset,
            limit,
        };
        if (user) {
            params = { ...params, user };
        }
        return new Promise((resolve) => {
            service
                .getAllTransactions(params)
                .then((response) => {
                    const data = transformData('id', response);
                    setDataTable(data);
                    setLoading(false);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    };

    /**
     * Handle onChange method
     * @param key input id
     * @param value input value
     */
    const onChangeDataForm = (key, value) => {
        const newValue = value;
        setFormData({ ...formData, [key]: newValue });
    };

    useEffect(() => {
        getTransactions(page, rowsPerPage);
    }, []);

    useEffect(() => {
        const count = page * rowsPerPage;
        getTransactions(count, count + rowsPerPage);
    }, [rowsPerPage, page]);

    return (
        <div className='ContainerTransactions'>
            <h1>Transactions</h1>
            <FilterForm
                formId={'transaction-list-admin'}
                catalogs={formCatalogs}
                onChangeDataForm={onChangeDataForm}
                formProvider={formProviderTransactions}
                dataForm={formData}
            />
            <div className='SearchContainer'>
                <ButtonInner
                    variant='contained'
                    onClick={() => getTransactions(zero, ten)}
                    startIcon={<SearchIcon />}
                >
                    Search
                </ButtonInner>
            </div>
            {loading ? (
                <CircularProgress
                    color='inherit'
                    className='LoadingTable'
                    style={{ marginTop: '50px' }}
                />
            ) : (
                <TableInner
                    headers={headers}
                    data={dataTable}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { CodeSmsInput } from '../../../Components/Presentation/CodeSmsInput';
import { FullScreenModal } from '../../../Components/Presentation/FullScreenModal';
import { four, six } from '../../../Constants/var.constants';
import messages from './messages';
import './style.css';
import useCountDown from '../../../Utils/countDown';

export const FormValidateSmsCode: React.FC<{
    onValidateCode: any;
    phone: string;
    onResendCode: any;
    onNavigateBack: any;
}> = (props) => {
    const [code, setCode] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleOnChange = (value: string) => {
        setCode(value);
        setButtonDisabled(value.length !== six);
    };

    const TIME_SECONDS = 60000;
    const TIME_INTERVAL = 500;
    const ONE_SECOND = 1000;
    const ZERO_FIX = 0;

    const { timeLeft, actions } = useCountDown(TIME_SECONDS, TIME_INTERVAL);

    React.useEffect(() => {
        actions.start();
    }, []);

    return (
        <FullScreenModal message={messages.otp} className='FormValidate'>
            <p>
                {messages.otp_info_message}
                ******{props.phone.slice(-four)}
            </p>
            <CodeSmsInput onChange={handleOnChange} />
            <p>{messages.didnt_received}</p>

            <div className='countdown-text'>
                {timeLeft / ONE_SECOND > ZERO_FIX ? (
                    <p>
                        Time Remaining:{' '}
                        {(timeLeft / ONE_SECOND).toFixed(ZERO_FIX)}
                    </p>
                ) : (
                    <p>Didn&apos;t recieve a code?</p>
                )}
                <Button
                    disabled={timeLeft / ONE_SECOND > ZERO_FIX}
                    className='TryAgain'
                    onClick={() => {
                        props.onResendCode();
                        actions.start();
                    }}
                >
                    {messages.try_again}
                </Button>
            </div>

            <p>
                {messages.or}
                {', '}
                <span className='TryAgain' onClick={props.onNavigateBack}>
                    {messages.other_number}
                </span>
            </p>
            <Button
                variant='contained'
                onClick={() => {
                    props.onValidateCode(props.phone, code);
                }}
                disabled={buttonDisabled}
            >
                {messages.validate}
            </Button>
        </FullScreenModal>
    );
};

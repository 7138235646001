import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreenModalConfirmation } from '../../../../Components/Presentation/FullScreenConfirmations';
import { ITransaction } from '../../../../Interfaces/auth.interfaces';
import messages from './messages';
import './style.css';
import Moment from 'moment';

const SATOSHI_CONVERSION_RATE = 100000000;

export const ShowConfirmation: React.FC<{
    transData: ITransaction;
    onContinue: () => void;
}> = (props) => (
    <>
        <FullScreenModalConfirmation
            message={messages.info}
            className='ShowTran'
        >
            <div className='svgBitcoin'></div>
            <div className='BitcointDetails'>
                <div className='label'>{messages.bitcoin_re}</div>
                <div className='text'>
                    {props.transData.txTotal / SATOSHI_CONVERSION_RATE}
                    {' BTC'}
                </div>
                <div className='label'>{messages.cash_amount}</div>
                <div className='text'>{`$${props.transData.amount}`}</div>
                <div className='label'>{messages.date}</div>
                <div className='text'>
                    {Moment(props.transData.createAt).format(
                        'MM/DD/YYYY hh:mm A',
                    )}
                </div>
            </div>
            <a className='continue' onClick={props.onContinue}>
                <div>
                    {messages.continue}
                    <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className='iconConfirm'
                        size='1x'
                    />
                </div>
            </a>
        </FullScreenModalConfirmation>
    </>
);

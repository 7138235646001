const messages = {
    scan: 'Please Present This Barcode To The Cashier At:',
    service_fee: 'Service fee:',
    current_exchange: 'Current Exchange Rate For BTC:',
    btc_to_receive: 'BTC that you receive:',
    purchase_non_refundable: 'Purchases Are Non-Refundable',
    values_may_vary:
        'The amount to receive may vary depending on the Exchange rate at the moment of processing the transaction',
    cancel: 'Cancel',
    waiting_for_transaction: 'Waiting transaction',
    expiry: 'Barcode Expiry:',
    cash_limit: 'Cash Limits $20 - $500',
    alert: 'IF SOMEONE CALLED YOU TO SEND MONEY STOP IMMEDIATELY AND DO NOT PURCHASE',
    foot_text1:
        'Additional Retail Service Fee Of Up To $4.95 & Limits Apply. If This Barcode Expires, You Can Generate A New One.' +
        ' Cryptocurrency Purchase Will Automatically Complete Following The Cash Load.',
    foot_text2: 'Transactions Are Non-Refundable',
    foot_text3: 'Subject To Terms & Conditions',
    foot_text4: '©2024 Green Dot Corporation. NMLS #914924;',
    foot_text5: 'Green Dot Bank NMLS #908739.',
    info_message: `Scroll through and read the entirety of these terms and conditions. \n \ 
Pellentesque posuere pretium turpis. Nam a egestas justo.\n\r\n \
Praesent dictum sed nisl eget interdum. Nunc lobortis ipsum vel sem bibendum tempus.\n\r\n \
Maecenas mattis ultricies convallis. Etiam at nibh sit amet odio feugiat iaculis eget eget felis.\n\r\n \
In eu rhoncus neque. Sed nec elit quis est posuere elementum at sit amet magna. Nam turpis nisl,\n\r\n \
facilisis et hendrerit vitae, consectetur a augue.\n\r\n \
`,
    Accept: 'Accept',
};

export default messages;

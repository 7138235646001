import axios from 'axios';
import { HttpStatusResponse } from '../Constants/http-status.const';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import {
    WhitelistFormValues,
    WhitelistResponse,
} from '../Interfaces/whitelist.interface';
import { createQueryByFilters } from '../Utils/general.utils';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data request of the phone whitelist
 */
export class WhitelistService extends AppService {
    public async getAllWhiteListPhones(
        queryParams: WhitelistFormValues & PaginationProperties,
    ): Promise<Array<WhitelistResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathWhitelist}${query}`;
        const token = await this.headers;
        console.log(path);
        try {
            const response = await axios.get(path, {
                headers: {
                    authorization: token.authorization,
                },
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body.list;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async editWhitelist(whitelistId: number): Promise<string> {
        const path = `${this.apiUrl}/${this.controllerPathWhitelist}/${whitelistId}`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async addNewWhitelist(phone: string, active: boolean): Promise<any> {
        const path = `${this.apiUrl}/${this.controllerPathWhitelist}`;
        const token = await this.headers;
        try {
            const response = await axios.post(
                path,
                {
                    phone,
                    active,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        authorization: token.authorization,
                    },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async bulkUpload(file: FormData): Promise<any> {
        const path = `${this.apiUrl}/${this.controllerPathWhitelist}/bulk`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'post',
                url: path,
                data: file,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

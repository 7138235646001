import type { ChartData } from 'chart.js';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    // eslint-disable-next-line prettier/prettier
    Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { one, zero } from '../../../Constants/var.constants';
import { IStatisticsTransactionDataPoint } from '../../../Interfaces/statistics.interface';
import { StatisticsService } from '../../../Services/statistics.service';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export const TransactionsGraph: React.FC<{ width?: string }> = (props) => {
    const [data, setData] = useState<ChartData<'line'>>();
    const [transactionsData, setTransactionsData] =
        useState<IStatisticsTransactionDataPoint[]>();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Transactions received vs completed',
            },
        },
        scales: {
            yCount: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            yAmount: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    useEffect(() => {
        const service = new StatisticsService();
        service.getTransactionsReceivedVSCompleted().then((response) => {
            setTransactionsData(response);
            const dataPoints = {
                labels: response.map((el) => {
                    const timestamp = el.date;
                    const dateFormat = new Date(timestamp);
                    const dateString =
                        dateFormat.getDate() +
                        '/' +
                        (dateFormat.getMonth() + one) +
                        '/' +
                        dateFormat.getFullYear();
                    return dateString;
                }),
                datasets: [
                    {
                        label: 'TX Received',
                        data: response.map(
                            (el: IStatisticsTransactionDataPoint) =>
                                el.receivedCount || zero,
                        ),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        yAxisID: 'yCount',
                    },
                    {
                        label: 'TX Completed',
                        data: response.map(
                            (el: IStatisticsTransactionDataPoint) =>
                                el.completedCount || zero,
                        ),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        yAxisID: 'yCount',
                    },
                    {
                        label: 'USD Received',
                        data: response.map(
                            (el: IStatisticsTransactionDataPoint) =>
                                el.completedAmount || zero,
                        ),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        yAxisID: 'yAmount',
                    },
                ],
            };
            setData(dataPoints);
        });
    }, []);

    return (
        <div style={{ width: props.width || '100%' }}>
            {transactionsData && data ? (
                <Line options={options} data={data}></Line>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

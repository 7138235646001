import Button from '@mui/material/Button';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import { FullScreenModal } from '../../../Components/Presentation/FullScreenModal';
import messages from './messages';

export const FormPhoneRequest: React.FC<{ onSendPhone: any }> = (props) => {
    const [phone, setPhone] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleOnChange = (event) => {
        const transformedPhone = event
            .replace(/\s/g, '')
            .replace(/\./g, '')
            .replace(/-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '');

        if (/^\+\d{10,15}$/.test(transformedPhone)) {
            setButtonDisabled(false);
            setPhone(transformedPhone);
        }
    };

    return (
        <FullScreenModal message={messages.welcome} className='FormPhone'>
            <p>{messages.otp_info_message}</p>
            <MuiPhoneNumber
                defaultCountry={'us'}
                autoFormat={true}
                onChange={handleOnChange}
                onlyCountries={['us']}
            />
            <Button
                variant='contained'
                onClick={() => {
                    props.onSendPhone(phone);
                }}
                disabled={buttonDisabled}
            >
                {messages.send_code}
            </Button>
        </FullScreenModal>
    );
};

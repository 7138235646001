import axios from 'axios';
import type { ChartData } from 'chart.js';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    // eslint-disable-next-line prettier/prettier
    Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { one } from '../../../Constants/var.constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export const BitcoinPriceGraph: React.FC<{ width?: string }> = (props) => {
    const [data, setData] = useState<ChartData<'line'>>();
    const [cryptos, setCryptos] = useState<number[][]>();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Bitcoin prices',
            },
        },
    };

    useEffect(() => {
        const url =
            'https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=90&interval=daily';

        axios.get(url).then((response) => {
            setCryptos(response.data);
            const dataPoints = {
                labels: response.data.prices.map((price: number[]) => {
                    const timestamp = price[0];
                    const dateFormat = new Date(timestamp);
                    const dateString =
                        dateFormat.getDate() +
                        '/' +
                        (dateFormat.getMonth() + one) +
                        '/' +
                        dateFormat.getFullYear();
                    return dateString;
                }),
                datasets: [
                    {
                        label: 'Price',
                        data: response.data.prices.map(
                            (price: number[]) => price[1],
                        ),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            };
            setData(dataPoints);
        });
    }, []);

    return (
        <div style={{ width: props.width || '100%' }}>
            {cryptos && data ? (
                <Line options={options} data={data}></Line>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

import type { ChartData } from 'chart.js';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { zero } from '../../../Constants/var.constants';
import { IStatisticsUsers } from '../../../Interfaces/statistics.interface';
import { StatisticsService } from '../../../Services/statistics.service';
import { getDynamicColor } from '../../../Utils/general.utils';

ChartJS.register(ArcElement, Tooltip, Legend);

export const TopUsersGraph: React.FC<{ width?: string }> = (props) => {
    const [data, setData] = useState<ChartData<'pie'>>();
    const [topUsersData, setTopUsersData] = useState<IStatisticsUsers[]>();

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Top Users',
            },
        },
    };

    useEffect(() => {
        const service = new StatisticsService();
        service.getTopUsers().then((response) => {
            setTopUsersData(response);

            const colors = response.map(() => {
                return getDynamicColor();
            });

            const dataPoints = {
                labels: response.map((el) => el.phone),
                datasets: [
                    {
                        label: 'TX Completed',
                        data: response.map(
                            (el: IStatisticsUsers) => el.count || zero,
                        ),
                        backgroundColor: colors,
                    },
                    {
                        label: 'USD',
                        data: response.map(
                            (el: IStatisticsUsers) => el.amount || zero,
                        ),
                        backgroundColor: colors,
                    },
                ],
            };
            setData(dataPoints);
        });
    }, []);

    return (
        <div style={{ width: props.width || '100%' }}>
            {topUsersData && data ? (
                <Pie data={data} options={options} />
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

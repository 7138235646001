import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom';
import { FullScreenModalBarcode } from '../../../../Components/Presentation/FullScreenModalBarcode';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
    IBarcodeGenerateOutput,
    ITransaction,
} from '../../../../Interfaces/auth.interfaces';
import useCountDown from '../../../../Utils/countDown';
import { BarcodePolling } from '../BarcodePolling';
import messages from './messages';
import './style.css';

// const SATOSHI_CONVERSION_RATE = 100000000;

export const ShowBarcode: React.FC<{
    onResult: (tx: ITransaction) => void;
    barcodeData: IBarcodeGenerateOutput;
    onCancel: () => void;
}> = (props) => {
    const { storeAddress } = useParams();
    const regex = /(<([^>]+)>)/gi;
    const result = storeAddress!.replace(regex, ' ');
    const TIME_SECONDS = 600000;
    const TIME_INTERVAL = 500;
    const ONE_SECOND = 1000;
    const ZERO_FIX = 0;

    const { timeLeft, actions } = useCountDown(TIME_SECONDS, TIME_INTERVAL);

    const onResult = async (tx: ITransaction) => {
        props.onResult(tx);
    };

    React.useEffect(() => {
        //setIsOpen(true);
        actions.start();
    }, []);

    return (
        <>
            <FullScreenModalBarcode
                message={messages.scan}
                address={result}
                className='ShowBarcode'
            >
                <Barcode value={props.barcodeData.barcode} />

                <div className='countdown-text'>
                    {timeLeft / ONE_SECOND > ZERO_FIX ? (
                        <p>
                            {messages.expiry}{' '}
                            {Math.floor(
                                // eslint-disable-next-line no-magic-numbers
                                timeLeft / ONE_SECOND / 60,
                            ).toFixed(ZERO_FIX)}
                            {' Minutes '}
                            {(
                                timeLeft / ONE_SECOND -
                                // eslint-disable-next-line no-magic-numbers
                                Math.floor(timeLeft / ONE_SECOND / 60) * 60
                            ).toFixed(ZERO_FIX)}
                            {' Seconds'}
                        </p>
                    ) : (
                        <p>Expired Barcode</p>
                    )}
                </div>

                <div className='cashLimit'>
                    <h2>{messages.cash_limit}</h2>
                </div>

                <div className='alert'>
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className='iconAlert'
                        size='5x'
                    />
                    <h6>{messages.alert}</h6>
                </div>

                <div className='footTextNomal'>
                    <p>{messages.foot_text1}</p>
                </div>
                <div className='footText'>
                    <p>{messages.foot_text2}</p>
                    <p>{messages.foot_text4}</p>
                    <p>{messages.foot_text5}</p>
                </div>

                <div className='BarcodeDetails'>
                    {/* <div>
                    <span>{messages.service_fee}</span>
                    <span>${props.barcodeData.coinhubFeeUSD}</span>
                </div> */}
                    {/* <div>
                    <span>{messages.current_exchange}</span>
                    <span>${props.barcodeData.rate}</span>
                </div> 
                    <div>
                        <span>{messages.btc_to_receive}</span>
                        <span>{props.barcodeData.bitcoinsToReceive}</span>
                    </div>*/}
                </div>
                <BarcodePolling
                    onResult={onResult}
                    barcode={props.barcodeData.barcode}
                ></BarcodePolling>
                <a className='CancelLink' onClick={props.onCancel}>
                    {messages.cancel}
                </a>
            </FullScreenModalBarcode>
        </>
    );
};

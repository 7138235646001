import { useEffect, useState } from 'react';
import './shell.css';
import { AppContext } from '../../../Context/app.context';
import { Header } from '../../../Components/Containers/Header';
import { SideMenu } from '../../../Components/Containers/SideMenu';
import { Footer } from '../../../Components/Containers/Footer';
import { UserData } from '../../../Interfaces/user.interface';

/**
 * Container all app.
 * @returns {void} .
 */
const Shell: React.FC<{ userData: UserData; children?: React.ReactNode }> = (
    props,
) => {
    const [userData, setUserData] = useState({} as UserData);

    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        setUserData(props.userData);
    }, []);
    console.log(userData.user);

    return (
        <AppContext.Provider
            value={{ user: userData.user, signOut: userData.signOut }}
        >
            <div className='ShellContainer'>
                <Header
                    menu={{
                        openMenu,
                        setOpenMenu,
                    }}
                />
                <div className='ChildrenContainer'>
                    <SideMenu
                        open={openMenu}
                        setOpen={(value) => setOpenMenu(value)}
                    />
                    <div className='Wrapper'>{props.children}</div>
                </div>
                <Footer />
            </div>
        </AppContext.Provider>
    );
};

export default Shell;

import {
    faCircleCheck,
    faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreenModalConfirmation } from '../../../../Components/Presentation/FullScreenConfirmations';
import {
    IBarcodeGenerateOutput,
    ITransaction,
} from '../../../../Interfaces/auth.interfaces';
import messages from './messages';
import './style.css';
import Moment from 'moment';
import { PaymentPolling } from '../PaymentPolling';
import BlockUi from '@ethanresnick/react-block-ui';
import '@ethanresnick/react-block-ui/style.css';
import { useState } from 'react';

export const PaymentReceived: React.FC<{
    transData: ITransaction;
    onResult: (tx: ITransaction) => void;
    barcodeData: IBarcodeGenerateOutput;
}> = (props) => {
    const onResult = async (tx: ITransaction) => {
        setLoading(false);
        setTrasactionData(tx);
        // props.onResult(tx);
    };

    const [loading, setLoading] = useState(true);
    const [transData, setTrasactionData] = useState<ITransaction>();

    return (
        <>
            <FullScreenModalConfirmation
                message={messages.info}
                className='ShowTran'
            >
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    className='iconAlert'
                    size='5x'
                />
                <div className='TransDetails'>
                    <div className='label'>{messages.cash_deposited}</div>
                    <div className='text'>{`$${props.transData.amount}`}</div>
                    <div className='label'>{messages.date}</div>
                    <div className='text'>
                        {Moment(props.transData.createAt).format(
                            'MM/DD/YYYY hh:mm A',
                        )}
                    </div>
                    <div className='label'>{messages.tx_id}</div>
                    <div className='text'>{props.transData.id}</div>
                </div>

                {!loading ? (
                    <p>{messages.approved}</p>
                ) : (
                    <p>{messages.cashRetailer}</p>
                )}

                <BlockUi tag='div' blocking={loading}></BlockUi>

                {!loading ? (
                    <a
                        className='continue'
                        onClick={() => props.onResult(transData!)}
                    >
                        <div>
                            {messages.continue}
                            <FontAwesomeIcon
                                icon={faCircleArrowRight}
                                className='iconConfirm'
                                size='1x'
                            />
                        </div>
                    </a>
                ) : null}
                <PaymentPolling
                    onResult={onResult}
                    barcode={props.barcodeData.barcode}
                ></PaymentPolling>
            </FullScreenModalConfirmation>
        </>
    );
};

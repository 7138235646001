const messages = {
    otp: 'Enter the verification code.',
    otp_info_message:
        'Let’s make sure it’s really you. We’ve just sent a text message with a verification code to the phone number ending in ',
    didnt_received: 'Please allow up to 60 seconds to receive the code',
    try_again: 'Try again',
    seconds: 'seconds',
    or: 'or',
    other_number: 'try with another number',
    validate: 'Validate',
};

export default messages;

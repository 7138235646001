import { useContext, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { one, zero } from '../../../Constants/var.constants';
import messages from './messages';
import './style.css';
import { Box, Toolbar } from '@mui/material';
import { WebviewContext } from '../../../Context/webview.context';
import { WVAuthService } from '../../../Services/wvauth.service';
import { useSnackbar } from 'notistack';
import toast, { Toaster } from 'react-hot-toast';

const { REACT_APP_TELLER_MAP, REACT_APP_ALL_MAP } = process.env;
const ROUTE_PREFIX = 'buy-btc';
console.log(process.env);

const USERNAME_KEY = 'username';
const AUTHTOKEN_KEY = 'authToken';
const FIRST_NAME = 'firstName';
const USER_ID = 'userid';

export const Map: React.FC = () => {
    const timeout = useRef();
    const navigate = useNavigate();
    const context = useContext(WebviewContext);
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const logout = async () => {
        const data = {};
        data[USERNAME_KEY] = '';
        data[AUTHTOKEN_KEY] = '';
        data[FIRST_NAME] = '';
        data[USER_ID] = '';
    };

    const onPopupClick = () => {
        timeout.current = setTimeout(() => {
            const customButtons = document.getElementsByClassName(
                'storepoint-cf-button',
            );
            const stores = document.getElementsByClassName('storepoint-name');
            const storesAddress =
                document.getElementsByClassName('storepoint-address');
            const popup = document.getElementsByClassName(
                'storepoint-location-popup',
            );
            for (let i = 0; i < customButtons.length; i++) {
                const button = customButtons[i] as HTMLAnchorElement;
                button.target = '_self';
                const url = new URL(button.href);
                if (!url.pathname.startsWith('/' + ROUTE_PREFIX)) {
                    button.href = `${ROUTE_PREFIX}${url.pathname.replace(
                        /\s/g,
                        '',
                    )}/${
                        stores[i]
                            ? stores[i].textContent
                            : popup[0].firstChild
                            ? (popup[0].firstChild as HTMLHeadingElement)
                                  .innerText
                            : ''
                    }/${
                        storesAddress[i]
                            ? storesAddress[i].textContent
                            : popup[0].firstChild
                            ? (popup[0].firstChild as HTMLHeadingElement)
                                  .innerText
                            : ''
                    }`;
                }
                // Avoid the whole page refresh by navigating internally using react
                button.onclick = (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    const url = new URL(
                        (event.currentTarget as HTMLAnchorElement).href,
                    );
                    new Promise((resolve) => {
                        WVAuthService.getsetting({
                            enqueueSnackbar,
                            logout,
                        })
                            .then((response) => {
                                if (response.body.buyBitcoin) {
                                    console.log(url.pathname);
                                    navigate(url.pathname);
                                } else {
                                    toast(
                                        `Our retailer cash load locations are currently unavailable while upgrading our service.\n
                                        Please visit a Coinhub Bitcoin ATM location by viewing our ATM map. \n
                                        Ours ATMs also provide higher $25,000 daily limits.`,
                                        {
                                            duration: 10000,
                                        },
                                    );
                                }
                                resolve(response);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                };
            }
            // eslint-disable-next-line no-magic-numbers
        }, 500) as any;
    };
    (window as any).STPT_LOCATION_CLICK = onPopupClick;
    (window as any).STPT_AFTER_LOCATIONS_UPDATE = onPopupClick;

    const loadScript = (mapId: string | undefined) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://cdn.storepoint.co/api/v1/js/${mapId}.js`;
        script.id = 'storepoint-script';
        script.async = !zero;
        document.head.appendChild(script);
    };

    useEffect(() => {
        if (document.getElementById('storepoint-script') === null) {
            const map_id =
                location.pathname === '/tellers'
                    ? REACT_APP_TELLER_MAP
                    : REACT_APP_ALL_MAP;
            loadScript(map_id);
        }
        return () => {
            // Remove mapbox and storepoint scripts
            const storeLinks = document.getElementsByTagName('link');
            const storeScripts = document.getElementsByTagName('script');
            for (const el of [...storeLinks, ...storeScripts]) {
                if (
                    el.getAttribute('href')?.indexOf('mapbox.com') !== -one ||
                    el.getAttribute('href')?.indexOf('storepoint.co') !== -one
                ) {
                    el.remove();
                }
            }
            clearTimeout(timeout.current);
        };
    }, []);

    return (
        <>
            <div>
                <Toaster />
            </div>
            <h2 className='BuyMapHeader'>{messages.header}</h2>
            {!context.isLoggedIn ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <div />
                        <Button
                            className='ButtonSign'
                            variant='contained'
                            onClick={() => {
                                new Promise((resolve) => {
                                    WVAuthService.getsetting({
                                        enqueueSnackbar,
                                        logout,
                                    })
                                        .then((response) => {
                                            if (response.body.appLogin) {
                                                navigate('/map');
                                            } else {
                                                toast(
                                                    `Our retailer cash load locations are currently 
                                                        unavailable while upgrading our service.\n
                                                        Please visit a Coinhub Bitcoin ATM location by viewing our ATM map. \n
                                                        Ours ATMs also provide higher $25,000 daily limits.`,
                                                    {
                                                        duration: 10000,
                                                    },
                                                );
                                            }
                                            resolve(response);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                });
                            }}
                        >
                            {messages.sign_in}
                        </Button>
                    </Toolbar>
                </Box>
            ) : (
                <></>
            )}
            <div
                id='storepoint-container'
                data-map-id={
                    location.pathname === '/tellers'
                        ? REACT_APP_TELLER_MAP
                        : REACT_APP_ALL_MAP
                }
                data-tags={
                    location.pathname === '/tellers' ? 'bitcoin teller' : ''
                }
            ></div>
        </>
    );
};

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useRef, useState } from 'react';
import { FullScreenModal } from '../../../Components/Presentation/FullScreenModal';
import messages from './messages';
import './style.css';
import { Divider } from '@mui/material';

export const FormPersonalInfo: React.FC<{ onCreateRequest: any }> = (props) => {
    const [formValues, setFormValues] = useState({
        ssn: {
            value: '',
            error: false,
            errorMessage: messages.error_SSN,
        },
    });
    const hiddenFileInput = useRef<any>();
    const [selectedFile, setSelectedFile] = React.useState<any>(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
            },
        });
    };

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            console.log('here it works in Safari');
            hiddenFileInput.current.click();
        }
    };

    return (
        <FullScreenModal message={messages.personal} className='FormName'>
            <TextField
                placeholder='Enter SSN'
                label='Enter Social Security Number'
                name='ssn'
                variant='standard'
                fullWidth
                required
                value={formValues.ssn.value}
                onChange={handleChange}
                error={formValues.ssn.error}
                helperText={formValues.ssn.error && formValues.ssn.errorMessage}
                style={{ marginBottom: '20px' }}
            />

            <label
                htmlFor='images'
                className='drop-container'
                id='dropcontainer'
            >
                <span className='drop-title'>
                    Take Photo Or Upload Front of ID
                </span>
                <>
                    <Button variant='contained' onClick={handleClick}>
                        Choose Image
                    </Button>
                    <input
                        type='file'
                        id='images'
                        accept='image/*'
                        required
                        onChange={handleFileSelect}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                    />
                </>
            </label>
            <Divider orientation='horizontal' />
            <Button
                variant='contained'
                onClick={() => {
                    const formFields = Object.keys(formValues);
                    let newFormValues = { ...formValues };

                    for (let index = 0; index < formFields.length; index++) {
                        const currentField = formFields[index];
                        const currentValue = formValues[currentField].value;

                        if (currentValue === '') {
                            formValues.ssn.errorMessage = messages.error_SSN;
                            newFormValues = {
                                ...newFormValues,
                                [currentField]: {
                                    ...newFormValues[currentField],
                                    error: true,
                                },
                            };
                            setFormValues(newFormValues);
                            return;
                        }
                    }

                    const size = 15;
                    if (formValues.ssn.value.length > size) {
                        formValues.ssn.errorMessage = messages.error_size_ssn;
                        setFormValues(newFormValues);
                        return;
                    }

                    if (!selectedFile) {
                        alert(messages.error_file);
                        return;
                    }

                    const formData = new FormData();
                    formData.append('file', selectedFile);
                    formData.append('ssn', newFormValues.ssn.value);

                    setFormValues(newFormValues);
                    props.onCreateRequest(formData);
                }}
            >
                {messages.send}
            </Button>
        </FullScreenModal>
    );
};

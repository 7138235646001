import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { five, milliseconds, zero } from '../../../../Constants/var.constants';
import { WebviewContext } from '../../../../Context/webview.context';
import { ITransaction } from '../../../../Interfaces/auth.interfaces';
import { WVAuthService } from '../../../../Services/wvauth.service';
import './style.css';

// 15 seconds
const INTERVAL = five * milliseconds;
enum States {
    received = 'received',
    completed = 'completed',
    inprocess = 'inprocess',
    validated = 'validated',
    invalid = 'invalid',
    failed = 'failed',
    sent = 'sent',
}

export const BarcodePolling: React.FC<{
    onResult: (txId: ITransaction) => void;
    barcode: string;
}> = (props) => {
    const [barcodeState, setBarcodeState] = useState<States>();
    const context = useContext(WebviewContext);
    const { enqueueSnackbar } = useSnackbar();

    // Global interval handler
    let interval;

    const startPolling = async (barcode: string) => {
        const transactions = await WVAuthService.polling(
            {
                token: context.authToken as string,
                barcode,
            },
            { enqueueSnackbar, logout: context.logout },
        );
        if (transactions.length !== zero) {
            if (transactions.some((tx) => tx.stage === States.failed)) {
                setBarcodeState(States.failed);
                clearInterval(interval);
            } else if (transactions.some((tx) => tx.stage === States.sent)) {
                const tx = transactions.find((tx) => tx.stage === States.sent);
                if (tx?.txTotal) {
                    setBarcodeState(States.sent);
                    clearInterval(interval);
                    props.onResult(tx!);
                }
            } else if (transactions.some((tx) => tx.stage === States.invalid)) {
                setBarcodeState(States.invalid);
                clearInterval(interval);
            } else if (
                transactions.some((tx) => tx.stage === States.completed)
            ) {
                setBarcodeState(States.completed);
                clearInterval(interval);
            } else if (
                transactions.some((tx) => tx.stage === States.received)
            ) {
                const tx = transactions.find(
                    (tx) => tx.stage === States.received,
                );
                setBarcodeState(States.received);
                props.onResult(tx!);
            }
        }
    };

    useEffect(() => {
        interval = setInterval(() => {
            startPolling(props.barcode);
        }, INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <span className='BarcodePollingNotification'>
                {!barcodeState && <></>}
                {/*{!barcodeState && (
                    <>
                        {messages.waiting_for_transaction}
                        <CircularProgress className='Orange' />
                    </>
                )}
                {barcodeState === States.invalid && (
                    <>
                        {messages.invalid}
                        <ErrorIcon color='error' fontSize='large' />
                    </>
                )}
                {barcodeState === States.failed && (
                    <>
                        {messages.failed}
                        <ErrorIcon color='error' fontSize='large' />
                    </>
                )}
                {barcodeState === States.sent && (
                    <>
                        {messages.sent}
                        <CheckCircleIcon color='success' fontSize='large' />
                    </>
                )}
                {barcodeState === States.completed && (
                    <>
                        {messages.completed}
                        <CheckCircleIcon color='success' fontSize='large' />
                    </>
                )}
                {barcodeState === States.received && (
                    <>
                        {messages.processing}
                        <CircularProgress className='Orange' />
                    </>
                )}*/}
            </span>
        </>
    );
};

const messages = {
    terms: 'Agree to Terms And Conditions',
    info_message: `Scroll through and read the entirety of these terms and conditions. \n \ 
Pellentesque posuere pretium turpis. Nam a egestas justo.\n\r\n \
Praesent dictum sed nisl eget interdum. Nunc lobortis ipsum vel sem bibendum tempus.\n\r\n \
Maecenas mattis ultricies convallis. Etiam at nibh sit amet odio feugiat iaculis eget eget felis.\n\r\n \
In eu rhoncus neque. Sed nec elit quis est posuere elementum at sit amet magna. Nam turpis nisl,\n\r\n \
facilisis et hendrerit vitae, consectetur a augue.\n\r\n \
`,
    Accept: 'Accept',
    cancel: 'Decline',
};

export default messages;

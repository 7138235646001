const messages = {
    info: 'BITCOIN CONFIRMATION',
    service_fee: 'Service fee:',
    current_exchange: 'Current Exchange Rate For BTC:',
    btc_to_receive: 'BTC Received: ',
    bitcoin_re: 'Receive Bitcoin:',
    date: 'Date: ',
    cash_amount: 'Cash Amount: ',
    purchase_non_refundable: 'Purchases Are Non-Refundable',
    values_may_vary:
        'The amount to receive may vary depending on the Exchange rate at the moment of processing the transaction',
    cancel: 'Cancel',
    continue: 'Continue',
    waiting_for_transaction: 'Waiting transaction',
};

export default messages;

const messages = {
    info: 'PAYMENT SUCCESSFUL',
    service_fee: 'Service fee:',
    current_exchange: 'Current Exchange Rate For BTC:',
    btc_to_receive: 'BTC Received: ',
    cash_deposited: 'Cash Received: ',
    date: 'Date: ',
    tx_id: 'Transaction ID: ',
    purchase_non_refundable: 'Purchases Are Non-Refundable',
    values_may_vary:
        'The amount to receive may vary depending on the Exchange rate at the moment of processing the transaction',
    cancel: 'Cancel',
    continue: 'Continue',
    waiting_for_transaction: 'Waiting transaction',
};

export default messages;

import {
    faCircleCheck,
    faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreenModalConfirmation } from '../../../../Components/Presentation/FullScreenConfirmations';
import { ITransaction } from '../../../../Interfaces/auth.interfaces';
import messages from './messages';
import './style.css';
import Moment from 'moment';

export const ShowTransaction: React.FC<{
    transData: ITransaction;
    onContinue: () => void;
}> = (props) => (
    <>
        <FullScreenModalConfirmation
            message={messages.info}
            className='ShowTran'
        >
            <FontAwesomeIcon
                icon={faCircleCheck}
                className='iconAlert'
                size='5x'
            />
            <div className='TransDetails'>
                <div className='label'>{messages.cash_deposited}</div>
                <div className='text'>{`$${props.transData.amount}`}</div>
                <div className='label'>{messages.date}</div>
                <div className='text'>
                    {Moment(props.transData.createAt).format(
                        'MM/DD/YYYY hh:mm A',
                    )}
                </div>
                <div className='label'>{messages.tx_id}</div>
                <div className='text'>{props.transData.id}</div>
            </div>
            <a className='continue' onClick={props.onContinue}>
                <div>
                    {messages.continue}
                    <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className='iconConfirm'
                        size='1x'
                    />
                </div>
            </a>
        </FullScreenModalConfirmation>
    </>
);

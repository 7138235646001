import { EdgeProvider } from '../Interfaces/edge.provider.interface';

const EdgeReadyEvent = 'edgeProviderReady';
const zero = 0;

export class Edge {
    public static isEdge = (): boolean => {
        return window.navigator.userAgent.indexOf('app.edge') >= zero;
    };

    public static getProvider(): Promise<EdgeProvider> {
        return new Promise((resolve) => {
            // Use mock un dev mode
            if (window['edgeProvider'] != null) {
                resolve(window['edgeProvider']);
            } else {
                document.addEventListener(
                    EdgeReadyEvent,
                    () => {
                        resolve(window['edgeProvider']);
                    },
                    { once: true },
                );
            }
        });
    }
}

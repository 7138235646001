import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../../../../Context/loader.context';
import { WebviewContext } from '../../../../Context/webview.context';
import {
    IBarcodeGenerateOutput,
    ITransaction,
} from '../../../../Interfaces/auth.interfaces';
import { WVAuthService } from '../../../../Services/wvauth.service';
import { FormBuy } from '../FormBuy';
import { ShowBarcode } from '../ShowBarcode';
import { ShowConfirmation } from '../ShowConfirmation';
import { ShowSucces } from '../ShowSuccess';
import { ShowTransaction } from '../ShowTransaction';
import { Terms } from '../Terms';
import messages from './messages';
import { PaymentReceived } from '../PaymentReceived';

enum STATES {
    generateBarcode = 'generateBarcode',
    barcodeGenerated = 'barcodeGenerated',
    transactionResult = 'transactionResult',
    transactionBitcoin = 'transactionBitcoin',
    transactionSuccess = 'transactionSuccess',
    paymentReceived = 'paymentReceived',
    terms = 'terms',
}

const TIME_OUT = 5000;

export const BuyBTC: React.FC = () => {
    const [flowState, setFlowState] = useState(STATES.generateBarcode);
    const context = useContext(WebviewContext);
    const { storeId } = useParams();
    const navigate = useNavigate();
    const [barcodeData, setBarcodeData] = useState<IBarcodeGenerateOutput>();
    const { enqueueSnackbar } = useSnackbar();
    const { startLoader, stopLoader } = useLoader();
    const [transData, setTrasactionData] = useState<ITransaction>();

    if (!storeId) {
        navigate('/');
        enqueueSnackbar(messages.invalid_store);
    }

    const onBuy = async (amount: number, name: string) => {
        startLoader();
        const addressInfo = await context.edgeProvider?.getReceiveAddress({
            metadata: {
                // Optional metadata to tag incoming transactions with
                name: 'Coinhub Buy BTC',
                category: 'Exchange:Buy BTC',
                notes: `Purchase $${amount} USD`,
            },
        });

        const barcodeData = await WVAuthService.generateBarcode(
            {
                token: context.authToken as string,
                input: {
                    phone: context.username as string,
                    address: addressInfo?.publicAddress || '',
                    amount: amount,
                    retailerKey: storeId as string,
                    name: name,
                },
            },
            { enqueueSnackbar, logout: context.logout },
        );
        if (barcodeData) {
            setBarcodeData(barcodeData);
            setFlowState(STATES.barcodeGenerated);
        }
        stopLoader();
    };

    const onResult = async (tx: ITransaction) => {
        startLoader();

        if (tx) {
            setTimeout(function () {
                setFlowState(STATES.paymentReceived);
                stopLoader();
            }, TIME_OUT);
            setTrasactionData(tx);
        }

        stopLoader();
    };

    const accptTerms = async () => {
        startLoader();
        setFlowState(STATES.generateBarcode);
        stopLoader();
    };

    const continueBitcoin = async () => {
        startLoader();
        setFlowState(STATES.transactionBitcoin);
        stopLoader();
    };

    const continuePayment = async (tx: ITransaction) => {
        startLoader();

        if (tx) {
            setFlowState(STATES.transactionResult);
            stopLoader();
            setTrasactionData(tx);
        }
        stopLoader();
    };

    const continueSuccess = async () => {
        startLoader();
        setFlowState(STATES.transactionSuccess);
        stopLoader();
    };

    return (
        <>
            {STATES.generateBarcode === flowState && (
                <FormBuy
                    onBuy={onBuy}
                    onCancel={() => {
                        navigate('/');
                    }}
                ></FormBuy>
            )}
            {STATES.barcodeGenerated === flowState && (
                <ShowBarcode
                    onResult={onResult}
                    barcodeData={barcodeData as IBarcodeGenerateOutput}
                    onCancel={() => {
                        navigate('/');
                    }}
                ></ShowBarcode>
            )}
            {STATES.transactionResult === flowState && (
                <ShowTransaction
                    transData={transData as ITransaction}
                    onContinue={continueBitcoin}
                ></ShowTransaction>
            )}
            {STATES.paymentReceived === flowState && (
                <PaymentReceived
                    transData={transData as ITransaction}
                    onResult={continuePayment}
                    barcodeData={barcodeData as IBarcodeGenerateOutput}
                ></PaymentReceived>
            )}
            {STATES.transactionBitcoin === flowState && (
                <ShowConfirmation
                    transData={transData as ITransaction}
                    onContinue={continueSuccess}
                ></ShowConfirmation>
            )}
            {STATES.transactionSuccess === flowState && (
                <ShowSucces
                    transData={transData as ITransaction}
                    onContinue={() => {
                        navigate('/transactions');
                    }}
                ></ShowSucces>
            )}
            {STATES.terms === flowState && (
                <Terms
                    accptTerms={accptTerms}
                    onCancel={() => {
                        navigate('/');
                    }}
                ></Terms>
            )}
        </>
    );
};

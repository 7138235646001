import React from 'react';
import { IWebviewContext } from '../Interfaces/webview.interface';

/*
 * Initial data from context
 */
const initContext: IWebviewContext = {
    edgeProvider: undefined,
    username: undefined,
    authToken: undefined,
    logout: () => {
        /* Intentional empty */
    },
    isLoggedIn: false,
};

export const WebviewContext = React.createContext(initContext);

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { FullScreenContainer } from '../../Containers/FullScreenContainer';

export const FullScreenLoader: React.FC = () => {
    return (
        <FullScreenContainer zIndex={3}>
            <CircularProgress color='inherit' className='MessageYellow' />
        </FullScreenContainer>
    );
};

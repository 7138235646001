// import {
//     Button,
//     IconButton, Paper,
//     TextField
// } from '@material-ui/core';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useRef, useState } from 'react';
import { one, six, thirtytwo, zero } from '../../../Constants/var.constants';
import './style.css';

const CODE_LENGTH = new Array(six).fill(zero);

export const CodeSmsInput: React.FC<{ onChange: any }> = (props) => {
    const [value, setValue] = useState('');
    const [focused, isFocused] = useState(true);
    const input = useRef(null);

    useEffect(() => {
        handleClick();
    }, []);

    const inputElement = () => {
        const selectedIndex =
            value.length < CODE_LENGTH.length
                ? value.length
                : CODE_LENGTH.length - one;
        return (
            <div className='wrap' onClick={handleClick}>
                <input
                    value=''
                    ref={input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    onChange={handleChange}
                    className='CodeSmsInputWrapHiddenInput'
                    style={{
                        width: '32px',
                        top: '0px',
                        bottom: '0px',
                        left: `${selectedIndex * thirtytwo}px`,
                        opacity: value.length < CODE_LENGTH.length ? one : zero,
                    }}
                />
            </div>
        );
    };
    const handleClick = () => {
        if (input && input.current) (input.current as any).focus();
    };
    const handleFocus = () => {
        isFocused(true);
    };
    const handleBlur = () => {
        isFocused(false);
    };
    const handleKeyUp = (event) => {
        if (event.key === 'Backspace') {
            const newValue = value.slice(zero, value.length - one);
            setValue(newValue);
            props.onChange(newValue);
        }
    };
    const handleChange = (event) => {
        const newLetter = event.target.value;
        if (!newLetter.match(/\d{1}/)) {
            return;
        }
        const newValue = (value + newLetter).slice(zero, CODE_LENGTH.length);
        setValue(newValue);
        props.onChange(newValue);
    };

    const displayFrame = (index) => {
        const selected = value.length === index;
        const filled =
            value.length === CODE_LENGTH.length &&
            index === CODE_LENGTH.length - one;
        return (
            <div key={index} className='CodeSmsInputDisplay'>
                {value[index] || ''}
                {(selected || filled) && focused && (
                    <div className='CodeSmsInputShadows' />
                )}
            </div>
        );
    };

    return (
        <div className='CodeSmsInputContent'>
            <div className='CodeSmsInputWrap' onClick={handleClick}>
                {inputElement()}
                {CODE_LENGTH.map((_v, index) => {
                    return displayFrame(index);
                })}
            </div>
        </div>
    );
};

const messages = {
    info: 'Cash Payment Received',
    service_fee: 'Service fee:',
    current_exchange: 'Current Exchange Rate For BTC:',
    btc_to_receive: 'BTC Received: ',
    cash_deposited: 'Cash Received: ',
    date: 'Date: ',
    tx_id: 'Transaction ID: ',
    purchase_non_refundable: 'Purchases Are Non-Refundable',
    values_may_vary:
        'The amount to receive may vary depending on the Exchange rate at the moment of processing the transaction',
    cancel: 'Cancel',
    continue: 'Continue',
    waiting_for_transaction: 'Waiting transaction',
    approved:
        'Your transaction was approved and you may continue to get your receipt',
    cashRetailer: `Your cash at the retailer has been received. Please keep the retailer receipt that was provided and allow up 
    to 15 minutes for further internal processing to continue.`,
};

export default messages;

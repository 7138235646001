import { FullScreenContainer } from '../../Containers/FullScreenContainer';
import './style.css';

export const FullScreenModalBarcode: React.FC<{
    children?: React.ReactNode;
    message?: string;
    address?: string;
    className?: string;
}> = (props) => (
    <>
        <FullScreenContainer>
            <div className='FullScreenModalOutBox'>
                <h3>{props.message}</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div className='cvs'>CVS</div>
                            </td>
                        </tr>
                        <tr className='address'>
                            <td>{props.address}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={'FullScreenModalInBox' + ' ' + props.className}>
                    {props.children}
                </div>
            </div>
        </FullScreenContainer>
    </>
);

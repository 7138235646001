import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';

import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    // eslint-disable-next-line prettier/prettier
    ListItemText,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import { drawerWidth, one, zero } from '../../../Constants/var.constants';
import { stringPhoneAvatar } from '../../../Utils/general.utils';
import './style.css';
import {
    ListAltRounded,
    RequestPageTwoTone,
    Settings,
} from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(zero, one),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const adminList = [
    {
        id: 0,
        text: 'Dashboard',
        icon: <DashboardIcon />,
        divider: false,
        url: '/admin',
    },
    {
        id: 1,
        text: 'Users',
        icon: <PeopleIcon />,
        divider: false,
        url: '/admin/users',
    },
    {
        id: 2,
        text: 'Transactions',
        icon: <PaidIcon />,
        divider: false,
        url: '/admin/transactions',
    },
    {
        id: 3,
        text: 'My promo codes',
        icon: <ConfirmationNumberIcon />,
        divider: true,
        url: '/admin/users',
    },
    {
        id: 4,
        text: 'Whitelist',
        icon: <ListAltRounded />,
        divider: true,
        url: '/admin/whitelist',
    },
    {
        id: 5,
        text: 'Approval request',
        icon: <RequestPageTwoTone />,
        divider: false,
        url: '/admin/request',
    },
    {
        id: 6,
        text: 'Settings',
        icon: <Settings />,
        divider: true,
        url: '/admin/setting',
    },
];

export const SideMenu: React.FC<{
    open: boolean;
    menuList?: any[];
    username?: string;
    setOpen: (value) => void;
}> = ({ open, setOpen, menuList, username }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const menuElements = menuList || adminList;

    const goTo = (url) => {
        navigate(url);
        setOpen(false);
    };

    return (
        <Drawer
            sx={{
                flexShrink: zero,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant='persistent'
            anchor='left'
            open={open}
        >
            <DrawerHeader>
                {username && (
                    <div className='ProfilePicture'>
                        <Avatar {...stringPhoneAvatar(username)} />
                        <p>{username}</p>
                    </div>
                )}
                <IconButton onClick={() => setOpen(false)}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider style={{ marginTop: '10px' }} />
            <List>
                {menuElements.map((value) => (
                    <div key={value.id}>
                        {value.divider && <Divider />}
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => goTo(value.url)}>
                                <ListItemIcon>{value.icon}</ListItemIcon>
                                <ListItemText primary={value.text} />
                            </ListItemButton>
                        </ListItem>
                    </div>
                ))}
            </List>
        </Drawer>
    );
};

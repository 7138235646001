import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SideMenu } from '../../../Components/Containers/SideMenu';
import { WebviewContext } from '../../../Context/webview.context';
import { Routes } from './routes';
import './shell.css';

/**
 * Container all app.
 * @returns {void} .
 */
const WVShell: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [openMenu, setOpenMenu] = useState(false);
    const context = useContext(WebviewContext);
    const location = useLocation();

    return (
        <div className='WVShellContainer'>
            <div className='WVChildrenContainer'>
                {(context.isLoggedIn &&
                    location.pathname === '/transactions') ||
                (context.isLoggedIn && location.pathname === '/instructions') ||
                (context.isLoggedIn && location.pathname === '/map') ||
                (context.isLoggedIn && location.pathname === '/tellers') ||
                (context.isLoggedIn && location.pathname === '/') ? (
                    <>
                        <IconButton
                            color='default'
                            aria-label='open drawer'
                            className='DrawerButton'
                            onClick={() => setOpenMenu(true)}
                            edge='start'
                            sx={{
                                mr: 2,
                                ...(openMenu && { display: 'none' }),
                                color: 'white',
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <SideMenu
                            menuList={Routes}
                            open={openMenu}
                            username={context.username}
                            setOpen={(value) => setOpenMenu(value)}
                        />
                    </>
                ) : (
                    <></>
                )}
                <div className='WVWrapper'>{props.children}</div>
            </div>
        </div>
    );
};

export default WVShell;

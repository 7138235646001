import axios from 'axios';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data from settings
 */
export class SettingsService extends AppService {
    public async getSetting(settingId: number): Promise<any> {
        const path = `${this.apiUrl}/${this.controllerSetings}/${settingId}`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async editSettings(
        settingId: number,
        buy: boolean,
        login: boolean,
    ): Promise<any> {
        const path = `${this.apiUrl}/${this.controllerSetings}/${settingId}/${
            buy ? '1' : '0'
        }/${login ? '1' : '0'}`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

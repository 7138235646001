import { Divider, Stack } from '@mui/material';
import { FullScreenModal } from '../../../../Components/Presentation/FullScreenModal';
import messages from './messages';
import './style.css';

export const Terms: React.FC<{
    accptTerms: () => void;
    onCancel: () => void;
}> = (props) => {
    const terms = () => {
        return (
            <>
                <b>
                    Scroll through and read the entirety of these terms and
                    conditions.
                </b>
                <br></br>
                <br></br> - Purchases are for personal use only. Cash loads are
                non-refundable. Transaction follows through with auto-completion
                following a successful cash load.
                <br></br>
                <br></br> - Do not load cash for purchase if someone has called
                you claiming to make a payment or that you owe them money.
            </>
        );
    };

    return (
        <FullScreenModal message={`${messages.terms}`}>
            <div style={{ textAlign: 'left' }}> {terms()} </div>
            <Divider orientation='horizontal' />
            <Stack className='stack' spacing={2} direction='row'>
                <a className='CancelLink' onClick={props.accptTerms}>
                    {messages.Accept}
                </a>
                <a className='CancelLink' onClick={props.onCancel}>
                    {messages.cancel}
                </a>
            </Stack>
        </FullScreenModal>
    );
};

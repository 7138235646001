const messages = {
    profile: 'Complete Your Profile',
    otp_info_message: 'First Name',
    send: 'SEND',
    name: 'First Name',
    last_name: 'Last Name',
    error_name: 'First name required',
    error_last_name: 'Last name required',
};

export default messages;

import axios, { AxiosError } from 'axios';
import { HttpStatusResponse } from '../Constants/http-status.const';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import {
    IBarcodeGenerateOutput,
    IBarcodeGenerateRequest,
    IBitCoinExchange,
    IRequestCreateRequest,
    IRequestUser,
    ITransaction,
    // eslint-disable-next-line prettier/prettier
    IUserUpdateRequest,
} from '../Interfaces/auth.interfaces';
import { createQueryByFilters } from '../Utils/general.utils';
import { TransactionFormValues } from '../Views/Admin/TransactionList/transactionlist.type';

const { REACT_APP_API_URL, REACT_APP_API_REST_TOKEN } = process.env;

interface IApiOptions {
    logout: () => void;
    enqueueSnackbar: (message: string) => void;
}
/**
 * Class responsible for manage the API calls and data request of the Users
 */
export class WVAuthService {
    public static async sendSMS(
        params: { phone: string },
        options: IApiOptions,
    ): Promise<boolean> {
        const { phone } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/auth-send-sms`;
        try {
            await axios.post(
                path,
                {
                    phone,
                },
                {
                    headers: {
                        'x-api-key': REACT_APP_API_REST_TOKEN || '',
                    },
                },
            );
            return true;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return false;
            //return true;
        }
    }

    public static async getsetting(options: IApiOptions): Promise<any> {
        const path = `${REACT_APP_API_URL || ''}/rest/setting/1`;
        try {
            const { data } = await axios.get(path, {
                headers: {
                    'x-api-key': REACT_APP_API_REST_TOKEN || '',
                },
            });
            return data;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return false;
            //return true;
        }
    }

    public static async validateReview(
        params: { phone: string | undefined },
        options: IApiOptions,
    ): Promise<number | null> {
        const { phone } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/validate-request`;
        try {
            const { data } = await axios.post(
                path,
                {
                    phone,
                },
                {
                    headers: {
                        'x-api-key': REACT_APP_API_REST_TOKEN || '',
                    },
                },
            );
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
            //return ApiResultsMock.validateCode;
        }
    }

    public static async validateCode(
        params: { phone: string; code: string },
        options: IApiOptions,
    ): Promise<IRequestUser | null> {
        const { phone, code } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/auth-verify-code`;
        try {
            const { data } = await axios.post(
                path,
                {
                    phone,
                    code,
                },
                {
                    headers: {
                        'x-api-key': REACT_APP_API_REST_TOKEN || '',
                    },
                },
            );
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
            //return ApiResultsMock.validateCode;
        }
    }

    public static async generateBarcode(
        params: { token: string; input: IBarcodeGenerateRequest },
        options: IApiOptions,
    ): Promise<IBarcodeGenerateOutput | null> {
        const { token, input } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/barcode`;
        try {
            const { data } = await axios.post(path, input, {
                headers: {
                    Authorization: token,
                },
            });
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
            //return ApiResultsMock.generateBarcode;
        }
    }

    public static async updateUserName(
        params: { token: string | undefined; input: IUserUpdateRequest },
        options: IApiOptions,
    ): Promise<IRequestUser | null> {
        const { token, input } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/user`;
        try {
            const { data } = await axios.put(path, input, {
                headers: {
                    Authorization: token,
                },
            });
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
            //return ApiResultsMock.generateBarcode;
        }
    }

    public static async createRequest(
        params: { token: string | undefined; input: IRequestCreateRequest },
        options: IApiOptions,
    ): Promise<any> {
        const { token, input } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/request`;
        try {
            const { data } = await axios({
                method: 'post',
                url: path,
                data: input.file,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token,
                },
            });
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
        }
    }

    public static async calculateBTC(
        params: { token: string; amount: number },
        options: IApiOptions,
    ): Promise<IBitCoinExchange | null> {
        const { token, amount } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/calculate-btc`;
        try {
            const { data } = await axios.post(
                path,
                { amount },
                {
                    headers: {
                        Authorization: token,
                    },
                },
            );
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return null;
            //return ApiResultsMock.generateBarcode;
        }
    }

    public static async polling(
        params: { token: string; barcode: string },
        options: IApiOptions,
    ): Promise<ITransaction[]> {
        const { token, barcode } = params;
        const path = `${REACT_APP_API_URL || ''}/rest/barcode/${barcode}`;
        try {
            const { data } = await axios.get(path, {
                headers: {
                    Authorization: token,
                },
            });
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return [];
            //return ApiResultsMock.polling;
        }
    }

    public static async getUserTransactions(
        params: TransactionFormValues & PaginationProperties,
        token: string,
        options: IApiOptions,
    ): Promise<{ list: ITransaction[] }> {
        const query = createQueryByFilters(params);
        const path = `${REACT_APP_API_URL || ''}/rest/transactions${query}`;
        try {
            const { data } = await axios.get(path, {
                headers: {
                    Authorization: token,
                },
            });
            return data.body;
        } catch (error) {
            WVAuthService.handleError(error as AxiosError, options);
            return { list: [] };
            //return ApiResultsMock.getUserTransactions;
        }
    }

    private static handleError(err: AxiosError, options: IApiOptions) {
        const { enqueueSnackbar, logout } = options;
        if (
            err.response?.status === HttpStatusResponse.forbidden.status ||
            err.response?.status === HttpStatusResponse.unauthorized.status
        ) {
            logout();
            enqueueSnackbar('The request token is invalid, please login again');
        } else {
            if ((err.response?.data as any)?.message) {
                enqueueSnackbar(`${(err.response?.data as any).message}`);
            } else {
                enqueueSnackbar(
                    `An error happened, please try again or contact support!, Error: ${err.message}`,
                );
            }
        }
    }
}

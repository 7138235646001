import React, { useContext } from 'react';

import {
    Heading,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    // eslint-disable-next-line prettier/prettier
    Text,
} from '@aws-amplify/ui-react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { VscAccount } from 'react-icons/vsc';

import { drawerWidth } from '../../../Constants/var.constants';
import { AppContext } from '../../../Context/app.context';
import { UserData } from '../../../Interfaces/user.interface';
import './header.css';
import messages from './header.messages';
import { HeaderProps } from './header.type';

const coinatm = require('../../../Assets/images/coinatm.jpg');

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Header: React.FC<HeaderProps> = ({ menu }) => {
    //const { user, signOut } = userData;

    const { user, signOut } = useContext(AppContext) as UserData;

    return (
        <AppBar
            position='fixed'
            open={menu.openMenu}
            className='ContainerHeader'
        >
            <div className='ContainerHeader'>
                <div className='HeaderLeft'>
                    <IconButton
                        color='default'
                        aria-label='open drawer'
                        onClick={() => menu.setOpenMenu(true)}
                        edge='start'
                        sx={{
                            mr: 2,
                            ...(menu.openMenu && { display: 'none' }),
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Image
                        alt='coin atm logo'
                        src={coinatm}
                        objectFit='initial'
                        objectPosition='50% 50%'
                        backgroundColor='initial'
                        width='10%'
                        opacity='100%'
                        style={{ marginLeft: '20px' }}
                    />
                    <Heading paddingLeft={'15px'} level={3}>
                        {messages.title}
                    </Heading>
                </div>
                <div className='HeaderRight'>
                    <Menu
                        menuAlign='end'
                        trigger={
                            <MenuButton
                                size='small'
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    boxShadow: '0',
                                }}
                            >
                                <Text
                                    style={{
                                        paddingBottom: '15px',
                                        paddingRight: '10px',
                                    }}
                                >
                                    {user && user.username}
                                </Text>
                                <Icon
                                    ariaLabel='Javascript'
                                    as={VscAccount}
                                    height={'50px'}
                                    width={'50px'}
                                />
                            </MenuButton>
                        }
                    >
                        <MenuItem>{messages.profile}</MenuItem>
                        <MenuItem>{messages.promocode}</MenuItem>
                        <MenuItem onClick={signOut}>{messages.logout}</MenuItem>
                    </Menu>
                </div>
            </div>
        </AppBar>
    );
};

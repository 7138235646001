import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { IStatisticsWalletBalance } from '../../../Interfaces/statistics.interface';
import { StatisticsService } from '../../../Services/statistics.service';

ChartJS.register(ArcElement, Tooltip, Legend);

export const WalletBalance: React.FC<{ width?: string }> = (props) => {
    const [data, setData] = useState<IStatisticsWalletBalance>();
    const cssBlock = {
        margin: '10px 20px 10px 0',
        backgroundColor: 'green',
        flex: '0 0 150px',
        height: '150px',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    };

    useEffect(() => {
        const service = new StatisticsService();
        service.getWalletBalance().then((response) => {
            setData(response);
        });
    }, []);

    return (
        <>
            <span>
                <strong>Wallet balance</strong>
            </span>
            <div
                style={{
                    width: props.width || '100%',
                    display: 'flex',
                }}
            >
                {data ? (
                    <>
                        <div style={cssBlock as any}>
                            <span>
                                <strong>BITCOINS:</strong>
                            </span>
                            <span>{data.btc}</span>
                        </div>
                        <div style={cssBlock as any}>
                            <span>
                                <strong>USD:</strong>
                            </span>
                            <span>{data.usd}</span>
                        </div>
                    </>
                ) : (
                    <p>No data available</p>
                )}
            </div>
        </>
    );
};

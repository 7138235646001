import InfoIcon from '@mui/icons-material/Info';
import PaidIcon from '@mui/icons-material/Paid';
import StoreIcon from '@mui/icons-material/Store';

export const Routes = [
    {
        id: 0,
        text: 'Buy in store',
        icon: <StoreIcon />,
        divider: false,
        url: '/',
    },
    {
        id: 1,
        text: 'Instructions',
        icon: <InfoIcon />,
        divider: false,
        url: '/instructions',
    },
    {
        id: 2,
        text: 'Transactions',
        icon: <PaidIcon />,
        divider: false,
        url: '/transactions',
    },
];

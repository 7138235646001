import { FormLabelsData } from '../../../Components/Tables/FilterForm/filterform.type';
export const headers = ['Barcode', 'Stage', 'Teller', 'Amount', 'BTC Address'];

/**
 * It defines the form inputs, labels ad validations
 */
export const formProviderTransactions: Array<FormLabelsData> = [
    {
        placeholder: 'Barcode',
        label: 'Barcode',
        elementId: 'barcode',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        placeholder: 'BTC Address',
        label: 'BTC Address',
        elementId: 'address',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        placeholder: 'Teller',
        label: 'Teller',
        elementId: 'teller',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        label: 'Stage',
        elementId: 'stage',
        type: 'select',
        catalog: 'stages',
        catalogId: 'value',
        catalogName: 'name',
        aliasKey: 'stage',
        sizeClass: '250px',
        styleClass: 'TransactionItem label-background-blue',
    },
];

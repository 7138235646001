import React from 'react';
import { FullScreenModal } from '../../../Components/Presentation/FullScreenModal';
import messages from './messages';
import { Stack } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

export const RequestUnderReview: React.FC<{
    onSendPhone: any;
    onCancel: () => void;
}> = (props) => {
    return (
        <FullScreenModal message={messages.welcome} className='FormPhone'>
            <p>
                <CheckCircle></CheckCircle>
            </p>
            <p>{messages.otp_info_message} </p>
            <p>{messages.otp_info_row2}</p>
            <Stack className='stack' spacing={2} direction='row'>
                <a className='CancelLink' onClick={props.onCancel}>
                    {messages.return}
                </a>
            </Stack>
        </FullScreenModal>
    );
};

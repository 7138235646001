import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class AppService {
    public readonly controllerPathUsers = 'rest/admin/users';
    public readonly controllerPathWhitelist = 'rest/admin/whitelist';
    public readonly controllerSetings = 'rest/admin/setting';
    public readonly controllerPathRequest = 'rest/admin/request';
    public readonly controllerPathStatisticstransactions =
        'rest/admin/statistics/transactions';
    public readonly controllerPathStatisticsStores =
        'rest/admin/statistics/stores';
    public readonly controllerPathStatisticsUsers =
        'rest/admin/statistics/users';
    public readonly controllerPathStatisticsWallet =
        'rest/admin/statistics/wallet';
    public readonly controllerPathTransactions = 'rest/admin/transactions';
    public headers;
    public apiUrl = process.env.REACT_APP_API_URL;

    public readonly postHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    constructor() {
        this.headers = this.authHeader();
    }

    public authHeader = async () => {
        await fetchAuthSession().then((session) => {
            console.log('test');
            console.log(session);
        });
        const { tokens } = await fetchAuthSession();
        /* const jwtToken = (await fetchAuthSession())
             .getIdToken().
             .getJwtToken();**/
        console.log(tokens);

        if (tokens?.idToken) {
            return { authorization: tokens?.idToken };
        } else {
            return {};
        }
    };
}

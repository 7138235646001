import React, { useEffect, useState } from 'react';
import './setting.css';
import { SettingResponse } from '../../../Interfaces/setting.interface';
import { SettingsService } from '../../../Services/settings.service';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
} from '@mui/material';
import messages from './messages';
import toast, { Toaster } from 'react-hot-toast';
import BlockUi from '@ethanresnick/react-block-ui';
import '@ethanresnick/react-block-ui/style.css';

export const Setting: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        buy: {
            checked: false,
            error: false,
            errorMessage: 'error',
        },
        login: {
            checked: false,
            error: false,
            errorMessage: 'error',
        },
    });

    /**
     * Method to call data settings.
     * @returns {Promise<Array<SettingResponse>>}
     */
    const getSetting = (): Promise<Array<SettingResponse | []>> => {
        setLoading(true);
        const service = new SettingsService();
        return new Promise((resolve) => {
            service
                .getSetting(Number('1'))
                .then((response) => {
                    console.log(response.body.buyBitcoin);
                    setFormValues({
                        buy: {
                            checked: response.body.buyBitcoin,
                            error: false,
                            errorMessage: '',
                        },
                        login: {
                            checked: response.body.appLogin,
                            error: false,
                            errorMessage: '',
                        },
                    });
                    setLoading(false);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    };

    const editSettings = (buy: boolean, login: boolean) => {
        setLoading(true);
        const service = new SettingsService();
        return new Promise(() => {
            service
                .editSettings(Number('1'), buy, login)
                .then(() => {
                    toast.success('Edited Setting!');
                    setLoading(false);
                    getSetting();
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast.error(error);
                });
        });
    };

    useEffect(() => {
        getSetting();
    }, []);

    /**
     * Method to edit status from approval request.
     * @param requestId request identifier.
     */

    const handleChange = (e) => {
        const { name, checked } = e.target;
        console.log(checked);
        console.log(name);
        console.log({
            ...formValues,
            [name]: {
                ...formValues[name],
                checked,
            },
        });
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                checked,
            },
        });
    };
    return (
        <BlockUi tag='div' blocking={loading}>
            <div>
                <Toaster />
            </div>
            <Paper
                style={{
                    display: 'grid',
                    gridRowGap: '20px',
                    padding: '20px',
                    margin: '10px 300px',
                }}
            >
                <Box sx={{ display: 'flex' }} width={500}>
                    <FormControl
                        sx={{ m: 3 }}
                        component='fieldset'
                        variant='standard'
                    >
                        <FormLabel component='legend'>Settings</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='buy'
                                        checked={formValues.buy.checked}
                                        onChange={handleChange}
                                    />
                                }
                                label='Buy Bitcoin'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='login'
                                        checked={formValues.login.checked}
                                        onChange={handleChange}
                                    />
                                }
                                label='Login to app'
                            />
                        </FormGroup>
                        <Button
                            variant='contained'
                            onClick={() => {
                                editSettings(
                                    formValues.buy.checked,
                                    formValues.login.checked,
                                );
                            }}
                        >
                            {messages.save}
                        </Button>
                    </FormControl>
                </Box>
            </Paper>
        </BlockUi>
    );
};

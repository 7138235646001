import React from 'react';

import { UserData } from '../Interfaces/user.interface';

/*
 * Initial data from context
 */
const initContext: UserData = {
    user: undefined,
    signOut: undefined,
};

export const AppContext = React.createContext(initContext);
